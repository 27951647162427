import React, { useEffect, useState } from 'react'
import { Link, Card, Box, SxProps, Button, Typography, Grid } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import GoogleSignInButton from 'components/auth/GoogleSignInButton'
import MicrosoftSignIn from 'components/auth/MicrosoftSignInButton'
import { Media } from 'service/media'
import {
  firebaseGoogleAuth,
  firebaseMicrosoftAuth,
} from 'service/authApi'
import { logInfo } from 'log'
import { auth } from 'service/api'
import SignupCarousel from 'components/help/SignupCarousel'
import ManualSignUp from './ManualSignup'
// import GoogleConsentButton from './GoogleConsentButton'

interface Props {
  signup?: boolean
}
export default function Access({ signup }: Props) {
  const navigate = useNavigate()
  const location = useLocation()
  const fbUser = auth.currentUser
  const currentParams = new URLSearchParams(location.search) // may contain affiliate
  const signUpIn = signup ? 'Sign Up' : 'Sign In'
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const loadingParamsScreen = `${Path.Loading}?${currentParams.toString()}`

  useEffect(() => {
    logInfo(`${signUpIn} Screen`)
    localStorage.clear()
  }, [])

  useEffect(() => {
    if (fbUser)
      navigate(loadingParamsScreen)
  }, [fbUser])

  async function signInGoogle() {
    setBusy(true)
    setErr(undefined)
    const res = await firebaseGoogleAuth()
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      navigate(loadingParamsScreen)
    }
    setBusy(false)
  }

  async function signInMicrosoft() {
    setBusy(true)
    setErr(undefined)
    const res = await firebaseMicrosoftAuth()
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      navigate(loadingParamsScreen)
    }
    setBusy(false)
  }

  return (
    <Box sx={sxPage}>
      <Box sx={sxRow} onClick={() => window.open('https://finmate.ai', '_blank')}>
        <Box component='img' alt='Finmate AI logo' sx={sxIcon} src={Media.logo100} />
        <Typography variant='h1'>FinMate AI</Typography>
      </Box>

      <Box sx={{ padding: '2vh' }} />

      <Card sx={sxCard}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={sxLeft}>
              {
                signup ? (
                  <Typography variant='h1'>Welcome!</Typography>
                ) : (
                  <Typography variant='h1'>Welcome Back!</Typography>
                )
              }

              <Box sx={{ padding: '1vh' }} />
              <Typography variant='h4'> {`${signUpIn} with your Email`}</Typography>

              <Box sx={{ padding: '1vh' }} />

              <Box sx={sxButtonWrapper}>
                <GoogleSignInButton
                  text={`${signUpIn} With Google`}
                  disable={busy}
                  onClick={signInGoogle}
                />
                {/* 
                keep this consent dialog if we need to do google oauth verification again. requires abide by AI.
                <GoogleConsentButton
                  text={`${signUpIn} With Google`}
                  disable={busy}
                  setDisable={setBusy} 
                  /> */}
              </Box>

              <Box sx={sxButtonWrapper}>
                <MicrosoftSignIn
                  text={`${signUpIn} With Microsoft`}
                  disable={busy}
                  onClick={signInMicrosoft}
                />
              </Box>

              <Box sx={{ padding: '0.5vh' }} />
              {err && <Typography color={'tomato'}>{err}</Typography>}
              <Box sx={{ padding: '0.5vh' }} />

              <Typography variant='caption'>
                By Using FinMate AI, you agree to the{' '}
                <Link rel='noopener' target='_blank' href={'https://www.finmate.ai/terms'}>
                  Terms of Service
                </Link>{', '}

                <Link rel='noopener' target='_blank' href='https://www.finmate.ai/privacy'>
                  Privacy Policy
                </Link>
                {', and '}
                <Link rel='noopener' target='_blank' href="https://finmate.ai/google">AI Limited Use Requirements</Link>
                .
                <br />
                {`FinMate AI's use and transfer to any other app of information received from Google APIs will adhere to `}
                <Link rel='noopener' target='_blank' href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</Link>
                {`, including the Limited Use requirements. `}

              </Typography>

            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={sxRight}>
              <SignupCarousel />
            </Box>
          </Grid>
        </Grid>
      </Card>

      <Box sx={{ padding: '1vh' }} />

      {
        signup ? (
          <Button aria-label='signin' onClick={() => navigate(Path.SignIn)} size='large' variant='outlined'>
            SignIn?
          </Button>
        ) : (
          <Button aria-label='signup' onClick={() => navigate(Path.SignUp)} size='large' variant='outlined'>
            SignUp?
          </Button>
        )
      }

      <ManualSignUp loadingPath={Path.Loading} />
    </Box >
  )
}

const sxPage: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  width: '100%',
}

const sxCard: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
}

const sxLeft: SxProps = {
  padding: '2vh',
}

const sxRight: SxProps = {
  padding: '2vh',
  backgroundColor: '#ACD5EB',
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
}

const sxButtonWrapper: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  margin: '3vh',
}

const sxRow: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}

const sxIcon: SxProps = {
  width: 40,
  height: 50,
  marginRight: 2,
}
