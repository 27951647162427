import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { Alert, Box, Button, Card, Checkbox, Dialog, FormControl, IconButton, Link, MenuItem, Select, Snackbar, SxProps, Typography } from '@mui/material'
import { logErr, logInfo } from '../../../log'
import { ActionItem, CRM, EventBase, WealthboxType, WealthboxVisibility } from '../../../client'
import { Media } from '../../../service/media'
import { gSx } from '../../../styles/Theme'
import { Path } from '../../../routes'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { addWealthboxClientNote, getWealthboxConfig } from 'service/integrations/wealthboxApi'
import { updateTimezone } from 'service'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import SelectTasks, { MasterTask } from '../SelectTasks'
import WealthboxClientCard from './WealthboxClientCard'

interface Props {
  open: boolean
  onClose: () => void
  event?: EventBase
  eventSummaryHtml?: string
}

export default function WealthboxDialog({ open, onClose, event, eventSummaryHtml }: Props) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const eventId = event?.id
  const hasClientEmail = event?.client?.email ?? false
  const { isBlocked } = useIsBlockedByPlan(true)

  const [wbType, setWbType] = useState<WealthboxType>(WealthboxType.PERSON)
  const [selectNotes, setSelectNotes] = useState(true)
  const [masterTasks, setMasterTasks] = useState<MasterTask[]>([])
  const [visibility, setVisibility] = useState(WealthboxVisibility.PRIVATE)

  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const { data: wbConfig, isLoading } = useQuery({
    queryKey: ['getWealthboxConfig'],
    queryFn: getWealthboxConfig,
  })

  useEffect(() => {
    if (wbConfig?.visibility)
      setVisibility(wbConfig?.visibility)
  }, [wbConfig])

  useEffect(() => {
    if (open) {
      logInfo('Wealthbox NoteSender Dialog Open')
      queryClient.invalidateQueries({ queryKey: ['getEvent', event?.id] })
    }
  }, [open])

  const disableSend = isBlocked || !hasClientEmail || busy

  async function onSend() {
    setBusy(true)
    setErr(undefined)

    const notes = selectNotes ? eventSummaryHtml : undefined
    const tasks: ActionItem[] = masterTasks.filter(m => m.isSelected)
    const res = await addWealthboxClientNote(event?.client, event?.id, tasks, wbType, notes, visibility)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      queryClient.invalidateQueries(['getEvent', eventId])
      setSnackSuccess(true)
      onClose()
    }
    setBusy(false)
  }

  function toBilling() {
    navigate(Path.Billing)
  }

  if (!eventId) {
    logErr('missing event id')
    return null
  }

  return (
    <>
      <Dialog open={open} onClose={() => onClose()}>
        <Box sx={sxContainer}>

          <Box sx={sxTop}>
            <Box sx={sxTopLeft} >
              <Box
                component="img"
                sx={icon}
                src={Media.wealthboxIcon}
                alt="Wealthbox"
              />
              <Typography variant='h3'>
                Send to Wealthbox
              </Typography>
            </Box>

            <IconButton aria-label='close' onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={sxContent}>
            <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 240px)' }}>
              {isBlocked &&
                <Typography sx={sxText}>{`Please `}
                  <Link onClick={toBilling}>Upgrade your plan</Link>{` to use Wealthbox`}
                </Typography>
              }
              <Box sx={{ padding: 1 }} />

              <WealthboxClientCard
                event={event}
                wbType={wbType}
                setWbType={setWbType}
                disabled={busy}
              />

              <Card>
                <Typography variant='h5'>Meeting Summary</Typography>
                <Box sx={{ padding: 1 }} />
                <Box sx={gSx.Row}>
                  <Checkbox
                    sx={{ padding: 0, marginLeft: 1, marginRight: 2 }}
                    checked={selectNotes}
                    onChange={(event, value) => setSelectNotes(value)}
                    disabled={busy}
                  />
                  <Typography>Include Meeting Summary as a Wealthbox Note</Typography>
                </Box>
              </Card>

              <Card>
                <Typography variant='h5'>Select Tasks</Typography>
                <Typography>All selected items will be saved as Wealthbox Tasks.</Typography>

                <Typography variant='caption'>
                  To Assign your teammates, they must be a member of a Wealthbox Team. (
                  <Link component="button" onClick={() => navigate(`${Path.WealthboxSettings}#teammates`)}>
                    learn more
                  </Link>
                  )
                </Typography>

                <SelectTasks
                  eventId={eventId}
                  masterTasks={masterTasks}
                  setMasterTasks={setMasterTasks}
                  disabled={busy}
                  crm={CRM.WEALTHBOX}
                />
              </Card >

              <Card>
                <Typography variant='h5'>Configurations</Typography>
                <Box sx={{ padding: 1 }} />
                <Box sx={gSx.RowBetween}>
                  <Box>
                    <Typography variant='h6'>Visibility</Typography>
                    <Typography>Wealthbox Notes and Tasks Visibility</Typography>
                  </Box>

                  <FormControl sx={{ width: 150 }}>
                    {
                      !isLoading &&
                      <Select
                        id="visibility"
                        value={visibility}
                        onChange={e => setVisibility(e.target.value as WealthboxVisibility)}
                        disabled={busy}
                      >
                        <MenuItem value={WealthboxVisibility.PRIVATE}>Only Me</MenuItem>
                        <MenuItem value={WealthboxVisibility.EVERYONE}>{WealthboxVisibility.EVERYONE}</MenuItem>
                      </Select>
                    }
                  </FormControl>
                </Box>
              </Card>

            </Box>

            <Box sx={sxBottom}>

              {event?.wealthbox_sent &&
                <Box sx={{ ...gSx.Row, paddingBottom: 2 }}>
                  <CheckIcon fontSize='small' sx={{ paddingX: 1, color: 'success.light' }} />
                  <Typography color='success.light'>Meeting details has already been sent</Typography>
                </Box>
              }

              <LoadingButton
                variant='contained'
                loading={busy}
                disabled={disableSend}
                onClick={onSend}
                sx={{ paddingX: 10 }}
              >
                Send to Wealthbox
              </LoadingButton>

              {
                err && <Box sx={{ paddingY: 2 }}>
                  <Typography sx={{ color: 'tomato' }}>{err}</Typography>
                  <Typography sx={{ color: 'tomato' }}>
                    Please refresh browser and try again. Re-sign into Wealthbox may also fix the issue. If issue remains, contact support.
                  </Typography>
                  <Button
                    onClick={() => navigate(Path.WealthboxSettings)}
                    variant='outlined'
                  >
                    Wealthbox Settings
                  </Button>
                </Box>
              }
            </Box>
          </Box>
        </Box>
      </Dialog >
      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Meeting Info has been sent to Wealthbox
        </Alert>
      </Snackbar>
    </>
  )
}

const sxContainer: SxProps = {
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  padding: 2,
  minWidth: 400,
}

const sxTop: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'start'
}

const sxTopLeft: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}

const icon: SxProps = {
  width: 40,
  height: 40,
  marginRight: 2
}

const sxText: SxProps = {
  paddingY: '5px',
  cursor: 'pointer'
}

const sxContent: SxProps = {
  flex: 1,
  overflow: 'auto',
}

const sxBottom: SxProps = {
  flex: 1,
  flexDirection: 'column',
  position: 'sticky',
  bottom: 0,
  paddingY: '5px',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}
