import React from 'react'
import {
  ButtonGroup,
  Button
} from '@mui/material'

import { Editor } from '@tiptap/react'

interface MenuProps {
  editor?: Editor | null
}

export const EditorMenuBar = ({ editor }: MenuProps) => {
  if (!editor) {
    return null
  }

  return (
    <div>
      <ButtonGroup size="small" aria-label="small button group">
        <Button onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} variant={editor.isActive('heading', { level: 3 }) ? "contained" : "outlined"} >header</Button>
        <Button onClick={() => editor.chain().focus().toggleBulletList().run()} variant={editor.isActive('bulletList') ? "contained" : "outlined"} >bullet list</Button>
        <Button onClick={() => editor.chain().focus().toggleOrderedList().run()} variant={editor.isActive('orderedList') ? "contained" : "outlined"} >ordered list</Button>
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          variant={editor.isActive('bold') ? "contained" : "outlined"}
        >
          bold
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          variant={editor.isActive('italic') ? "contained" : "outlined"}
        >
          italic
        </Button>
      </ButtonGroup>
      <ButtonGroup sx={{ paddingX: '5px' }} size="small" aria-label="small button group">
        <Button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .undo()
              .run()
          }
        >
          undo
        </Button>
        <Button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .redo()
              .run()
          }
        >
          redo
        </Button>
      </ButtonGroup>

    </div>
  )
}