import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, User, fetchSignInMethodsForEmail, signInWithRedirect, linkWithCredential } from 'firebase/auth'
import { config } from './api'
import { Api, auth } from '../service/api'
import { logErr, logInfo } from '../log'
import { isToday } from '../utils/date'
import { Path } from '../routes'
import { getErrorMessage } from 'utils'

export async function checkAuthStatus(): Promise<User | undefined> {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      (user) ? resolve(user) : resolve(undefined)
    }, reject)
  })
}

export async function getCalAuthToken(): Promise<string> {
  try {
    // if localstorage disabled, will fetch token every time.

    const calToken = localStorage.getItem('calToken')
    const calLastUpdated = localStorage.getItem('calLastUpdated')

    // token is valid 24 hours
    if (!calToken || !calLastUpdated || !isToday(calLastUpdated)) {
      const api = await Api()
      const res = await api.calendar.getRecallAuthToken()
      localStorage.setItem('calToken', res.token)
      localStorage.setItem('calLastUpdated', (new Date()).toISOString())
      return res.token
    } else {
      return calToken
    }
  } catch (e: any) {
    if ('status' in e && e['status'] == 401) {
      logInfo('getCalAuthToken not authenticated', { e })
    } else {
      logErr('getCalAuthToken', { e })
    }
  }
  return ''
}

export async function firebaseGoogleAuth() {
  try {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    const userCred = await signInWithPopup(auth, provider)
    const fbUser = userCred.user
    logInfo('SignIn Google')
    return true
  } catch (e) {
    logErr('firebaseGoogleAuth Error', { e })
    const err = e as { code?: string }
    if (err.code)
      return new Error(err.code)
    return new Error(String(e))
  }
}

export async function firebaseMicrosoftAuth() {
  // client secret expires in 2 years. start: May 18, 2023
  // https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade

  try {
    const auth = getAuth()
    const provider = new OAuthProvider('microsoft.com')

    provider.setCustomParameters({
      prompt: 'select_account'
    })

    const userCred = await signInWithPopup(auth, provider)
    const fbUser = userCred.user
    logInfo('SignIn Microsoft')
  } catch (e) {
    logErr('firebaseMicrosoftAuth Error', { e })
    const err = e as { code?: string }
    if (err.code)
      return new Error(err.code)
    return new Error(String(e))
  }
}

/**
   * 
   * @returns recall calendar V1 connection.
   */
const windowName = 'PopupWindow'
const windowFeatures = 'width=500,height=700'
export async function recallGoogleAuth(): Promise<void> {

  try {
    const token = await getCalAuthToken()
    const redirectUri = config.callbackHost + Path.AuthRecallGoogleCallback
    // const redirectUri = 'https://api.recall.ai/api/v1/calendar/google_oauth_callback/'
    const scopes = [
      'https://www.googleapis.com/auth/calendar.events.readonly',
      'https://www.googleapis.com/auth/userinfo.email',
    ]

    const oAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth?' +
      `scope=${scopes.join(' ')}` +
      '&access_type=offline' +
      '&prompt=consent' +
      '&include_granted_scopes=true' +
      '&response_type=code' +
      '&state={' +
      `"recall_calendar_auth_token":"${token}",` +
      `"google_oauth_redirect_url":"${redirectUri}"` +
      '}' +
      `&redirect_uri=${redirectUri}` +
      `&client_id=${config.googleClientId}`

    window.open(oAuthUrl, windowName, windowFeatures)
  } catch (err) {
    logErr('recallGoogleAuth', { err })
    localStorage.removeItem('calToken')
  }
}

/**
   * 
   * @returns recall calendar V1 connection.
   */
export async function recallMicrosoftAuth(): Promise<void> {
  try {
    const token = await getCalAuthToken()
    const redirectUri = config.callbackHost + Path.AuthRecallMicrosoftCallback
    // const redirectUri = 'https://api.recall.ai/api/v1/calendar/ms_oauth_callback/'
    const scopes = [
      'offline_access',
      'openid',
      'email',
      'https://graph.microsoft.com/Calendars.Read',
    ]

    const oAuthUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize' +
      `?client_id=${config.microsoftClientId}&` +
      '&response_type=code' +
      '&prompt=consent' +
      `&redirect_uri=${redirectUri}` +
      '&response_mode=query' +
      `&scope=${scopes.join(' ')}` +
      '&state={' +
      `"recall_calendar_auth_token":"${token}",` +
      `"ms_oauth_redirect_url":"${redirectUri}"` +
      '}'

    window.open(oAuthUrl, windowName, windowFeatures)
  } catch (err) {
    logErr('recallMicrosoftAuth', { err })
    localStorage.removeItem('calToken')
  }
}

export function isSuperUser() { // super admin
  const fbUser = auth.currentUser
  const userDomain = fbUser?.email?.split('@')[1]
  return (userDomain === 'trellish.com' || userDomain === 'finmate.ai')
}

export async function manualJoinAuth(email: string, password: string): Promise<void> {
  try {
    const auth = getAuth()
    const userCred = await createUserWithEmailAndPassword(auth, email, password)

  } catch (err) {
    logErr('manualJoinAuth', { err })
  }
}

export async function manualSignInAuth(email: string, password: string): Promise<void> {
  try {
    const auth = getAuth()
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    logErr('manualSignInAuth', { err })
  }
}
