/**
 * Calendar via Recall Calendar V1
 */

import { getErrorMessage } from 'utils'
import { BotPrefs, CalendarUser, CalendarEvent, EventInDb } from '../client'
import { logErr, logInfo } from '../log'
import { Api } from './api'
import { getCalAuthToken } from './authApi'

function makeHeader(token: string) {
  return {
    accept: 'application/json',
    'content-type': 'application/json',
    'x-recallcalendarauthtoken': token
  }
}

export function isConnected(cal?: CalendarUser): boolean {
  if (cal?.connections) {
    for (const c of cal.connections) {
      if (c.connected)
        return true
    }
  }
  return false
}

export async function ListCalendarMeetings(): Promise<CalendarEvent[] | undefined> {
  try {
    const api = await Api()
    return await api.calendar.getCalendarEvents()
  } catch (e) {
    logErr('ListCalendarMeetings', { e })
    localStorage.removeItem('calToken')
  }
}

export async function GetCalendarUser(): Promise<CalendarUser | undefined> {
  try {
    const token = await getCalAuthToken()
    const res = await fetch('https://api.recall.ai/api/v1/calendar/user/', {
      method: 'GET',
      headers: makeHeader(token),
    })
    const data = await res.json()
    return data
  } catch (e) {
    logErr('GetCalendarUser', { e })
    localStorage.removeItem('calToken')
  }
}

export async function UpdateCalendarMeeting(meetingId: string, overrideShouldRecord: boolean): Promise<EventInDb | Error> {
  try {
    const api = await Api()
    const eventDb = await api.events.updateCalendarMeeting(meetingId, overrideShouldRecord)
    logInfo('UpdateCalendarMeeting', { meetingId, eventDb })
    return eventDb
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('UpdateCalendarMeeting', { err })
    localStorage.removeItem('calToken')
    return err
  }
}

export async function deleteCalendarUser(): Promise<void> {
  const token = await getCalAuthToken()
  logInfo('Delete Calendar User')
  try {
    await fetch('https://api.recall.ai/api/v1/calendar/user/', {
      method: 'DELETE',
      headers: makeHeader(token),
    })
    logInfo('deleteCalendarUser')
  } catch (e) {
    logErr('deleteCalendarUser', { e })
  }
  localStorage.removeItem('calToken') // all conditions, remove
}

export async function UpdateRecordingPrefs(prefs: BotPrefs): Promise<CalendarUser | Error> {
  try {
    const token = await getCalAuthToken()
    logInfo('UpdateRecordingPrefs', { prefs })
    const res = await fetch('https://api.recall.ai/api/v1/calendar/user/', {
      method: 'PUT',
      headers: makeHeader(token),
      body: JSON.stringify({
        preferences: prefs
      })
    })
    const data = await res.json()
    logInfo('UpdateRecordingPrefs', { data, prefs })
    return data
  } catch (e) {
    localStorage.removeItem('calToken')
    const err = getErrorMessage(e)
    logErr('UpdateRecordingPrefs', { err })
    return err
  }
}

export function recordAllMeetings(): BotPrefs {
  // https://recallai.readme.io/reference/recording-preferences-logic
  return {
    record_non_host: false,
    record_recurring: false,
    record_external: true,
    record_internal: true,
    record_confirmed: false,
    record_only_host: false
  }
}

export function recordInternalMeetings(host: boolean): BotPrefs {
  return {
    record_external: false,
    record_internal: true,
    record_only_host: host,
  }
}

export function recordExternalMeetings(host: boolean): BotPrefs {
  return {
    record_external: true,
    record_internal: false,
    record_only_host: host,
  }
}

export function disableAutoRecordMeetings(): BotPrefs {
  // https://recallai.readme.io/reference/recording-preferences-logic
  return {
    record_non_host: false,
    record_recurring: false,
    record_external: false,
    record_internal: false,
    record_confirmed: false,
    record_only_host: false
  }
}

export function isZoomScheduled(calEvents?: CalendarEvent[]) {
  return calEvents?.some(e => e.meeting_platform === 'zoom')
}
