/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_addSalesforceClientNote } from '../models/Body_addSalesforceClientNote';
import type { SalesforceApp } from '../models/SalesforceApp';
import type { SalesforceBase } from '../models/SalesforceBase';
import type { SalesforceWho } from '../models/SalesforceWho';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesforceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Exchange Auth Code For Tokens
     * @param code
     * @param salesforceApp
     * @returns any Successful Response
     * @throws ApiError
     */
    public exchangeAuthCodeForTokens(
        code: string,
        salesforceApp: SalesforceApp,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/auth/code',
            query: {
                'code': code,
                'salesforce_app': salesforceApp,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save Salesforce Auth Localhost
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public saveSalesforceAuthLocalhost(
        requestBody: SalesforceBase,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/auth/save',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Salesforce
     * @returns SalesforceBase Successful Response
     * @throws ApiError
     */
    public getSalesforceSettings(): CancelablePromise<SalesforceBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/salesforce/',
        });
    }

    /**
     * Disconnect
     * @returns any Successful Response
     * @throws ApiError
     */
    public disconnectSalesforce(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/integrations/salesforce/',
        });
    }

    /**
     * Add Client Note
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public addSalesforceClientNote(
        requestBody: Body_addSalesforceClientNote,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/notes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Default Create Who
     * @param who
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateDefaultCreateWho(
        who: SalesforceWho,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/config/who',
            query: {
                'who': who,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Contacts
     * @returns number Successful Response
     * @throws ApiError
     */
    public importContacts(): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/contacts/import',
        });
    }

}
