import React, { useEffect, useState } from 'react'
import { Typography, SxProps, Box, Card, Button, TextField, IconButton, Snackbar, Alert, DialogActions, Skeleton } from '@mui/material'
import { gSx } from '../../styles/Theme'
import { ClientBase, ClientDetail } from '../../client'
import EditIcon from '@mui/icons-material/Edit'
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import { logErr, logInfo } from '../../log'
import { upsertClient } from 'service/clientApi'
import { LoadingButton } from '@mui/lab'

interface Props {
  data?: ClientDetail
}

export default function ClientContactEdit({ data }: Props) {
  const [isEdit, setEdit] = useState(false)

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [successSnack, setSuccessSnack] = useState(false)

  useEffect(() => {
    if (data) {
      setFirstname(data.first_name ?? '')
      setLastname(data.last_name ?? '')
      setEmail(data.email ?? '')
      setPhone(data.phone ?? '')
    }
  }, [data])

  async function onSave() {
    setBusy(true)
    setErr(undefined)
    if (!data) {
      setErr('missing client data')
      setBusy(false)
      return
    }
    const req: ClientBase = {
      id: data.id,
      first_name: firstname.trim(),
      last_name: lastname.trim(),
      phone: phone.trim(),
      email: email.trim().toLowerCase()
    }
    logInfo('Edit Client Pressed Save', { req })
    const res = await upsertClient(req)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSuccessSnack(true)
      setEdit(false)
      setErr(undefined)
      res.first_name && setFirstname(res.first_name)
      res.last_name && setLastname(res.last_name)
      res.phone && setPhone(res.phone)
      res.email && setEmail(res.email)
    }
    setBusy(false)
  }

  async function onCancel() {
    setFirstname(data?.first_name ?? '')
    setLastname(data?.last_name ?? '')
    setEmail(data?.email ?? '')
    setPhone(data?.phone ?? '')
    setEdit(false)
  }

  return (
    <>
      <Card sx={sxCard}>

        <Box sx={gSx.RowBetween}>
          <Typography variant='h4'>Client Contact</Typography>

          {
            !isEdit &&
            <IconButton
              onClick={() => setEdit(true)}
              disabled={!data}
            >
              <EditIcon fontSize='small' />
            </IconButton>
          }

        </Box>

        <Box sx={{ padding: 1 }} />

        {
          !data
            ? <Skeleton variant="rectangular" width={'100%'} height={110} />
            : (
              isEdit ? (
                <Box sx={sxEditBox}>

                  <Box sx={{ padding: '1vh' }} />

                  <Box sx={gSx.Row}>
                    <PersonIcon sx={{ mr: 1 }} />
                    <TextField
                      autoFocus
                      label='First Name'
                      variant="outlined"
                      value={firstname}
                      onChange={(t) => setFirstname(t.target.value)}
                      sx={sxTextField}
                    />
                  </Box>

                  <Box sx={gSx.Row}>
                    <Box sx={{ mr: 4 }} />
                    <TextField
                      label='Last Name'
                      variant="outlined"
                      value={lastname}
                      onChange={(t) => setLastname(t.target.value)}
                      sx={sxTextField}
                    />
                  </Box>

                  <Box sx={gSx.Row}>
                    <EmailIcon sx={{ mr: 1 }} />
                    <TextField
                      label='Email'
                      variant="outlined"
                      value={email}
                      sx={sxTextField}
                      disabled
                    />
                  </Box>

                  <Box sx={gSx.Row}>
                    <PhoneIphoneIcon sx={{ mr: 1, mb: 3 }} />
                    <TextField
                      label='Phone'
                      variant="outlined"
                      value={phone}
                      onChange={(t) => setPhone(t.target.value)}
                      sx={sxTextField}
                      helperText="ie. 123-456-7890"
                    />
                  </Box>

                  <DialogActions>
                    <Button
                      onClick={onCancel}
                      sx={sxButton}
                      disabled={busy}
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      variant='contained'
                      onClick={onSave}
                      sx={sxButton}
                      disabled={busy}
                      loading={busy}
                    >
                      Save
                    </LoadingButton>
                  </DialogActions>

                  {err && <Typography color='error.main'>{err}</Typography>}

                </Box>
              ) : (
                <Box sx={gSx.RowBetween}>
                  <Box>
                    <Box sx={gSx.Row}>
                      <PersonIcon sx={{ mr: 1, marginY: 1 }} />
                      <Typography variant='h3'>{`${firstname} ${lastname}`}</Typography>
                    </Box>

                    <Box sx={gSx.Row}>
                      <EmailIcon sx={{ mr: 1, marginY: 1 }} />
                      <Typography variant='h3'>{email}</Typography>
                    </Box>

                    <Box sx={gSx.Row}>
                      <PhoneIphoneIcon sx={{ mr: 1, marginY: 1 }} />
                      <Typography variant='h4'>{phone}</Typography>
                    </Box>

                  </Box>
                </Box>
              )

            )
        }
      </Card>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={successSnack}
        autoHideDuration={3000}
        onClose={() => setSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Client Details Updated
        </Alert>

      </Snackbar>
    </>
  )
}

const sxCard: SxProps = {
}

const sxEditBox: SxProps = {
  display: 'flex',
  flexDirection: 'column',
}

const sxTextField: SxProps = {
  width: '48vh',
  marginY: '1vh'
}

const sxButton: SxProps = {
  marginY: 2,
  width: '120px'
}
