import { EventSimpleBase } from "client"

export function isoDateToReadable(isoDate?: string): string {
  const d = isoDate ? new Date(isoDate) : new Date()
  return d.toLocaleDateString()
}

export function isToday(calAuthTokenLastUpdatedISO: string): boolean {
  const tokenDate = new Date(calAuthTokenLastUpdatedISO)
  const today = new Date()
  return today.toDateString() === tokenDate.toDateString()
}

export function numSecToTime(numSec: number): string {
  const seconds = Math.floor(numSec)
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  // Add leading zeros to single-digit minutes and seconds
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}

export function formatDateToMMMDDYYYY(date: Date): string {
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date)

  return formattedDate
}

export function minus1msec(iso_str?: string) {
  if (!iso_str)
    return
  const givenDatetime = new Date(iso_str)
  givenDatetime.setMilliseconds(givenDatetime.getMilliseconds() - 1)
  return givenDatetime.toISOString()
}

export function getEarliestDateofEvents(data?: EventSimpleBase[]) {
  if (!data || data.length == 0)
    return
  return data[data?.length - 1].start_time
}

export function getLatesttDateofEvents(data?: EventSimpleBase[]) {
  if (!data || data.length == 0)
    return
  return data[0].start_time
}

// Custom function to format seconds to hh:mm:ss
export const msToHhMmSs = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60)
  const seconds = totalSeconds - hours * 3600 - minutes * 60

  const padWithZero = (number: number) => {
    const string = number.toString()
    if (number < 10) {
      return '0' + string
    }
    return string
  }
  if (hours == 0)
    return `${padWithZero(minutes)}:${padWithZero(seconds)}`
  return `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(seconds)}`
}