import React, { useEffect, useRef, useState } from 'react'
import { Box, Card, Typography, Link, List, ListItem, Pagination, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { getEvents } from '../../service/eventApi'
import { useQuery } from '@tanstack/react-query'
import Grid from '@mui/material/Unstable_Grid2'
import EventListItem from './EventListItem'
import { useStore } from '../../hooks/useStore'
import SearchEventBar from './SearchEventBar'
import PaginationTitle from './PaginationTitle'
import { logInfo } from 'log'
import { ListEvents } from 'client'
import { ExistingEventsSkeleton } from 'components/Skeletons'
import Guide from 'components/help/DashboardGuide'

export default observer(MyEventsList)
function MyEventsList() {
  const store = useStore()
  const params = store.User.getEventSearchParams()
  const [page, setPage] = useState(1)
  const res = useRef<ListEvents | undefined>(undefined)

  const { data, isLoading } = useQuery({
    queryKey: ['getEvents', JSON.stringify(params), page],
    queryFn: async () => await getEvents(params, page, res.current),
  })

  useEffect(() => {
    res.current = data
  }, [data])

  const pageRange = data?.pages?.[0]

  const maxPages = Math.ceil((data?.total ?? 0) / (data?.page_limit ?? 1))

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    logInfo('Event List, date search, page button pressed', { page: value })
    setPage(value)
  }

  return (
    <Card>

      <SearchEventBar setPage={setPage} />

      <Grid container spacing={2} sx={{ paddingLeft: '30px', paddingRight: '30px' }}>
        <Grid xs={1.5} />
        <Grid xs={5.5} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography variant='h6' >Meeting Title</Typography>
        </Grid>
        <Grid xs={3} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography variant='h6'>Client</Typography>
        </Grid>
        <Grid xs={2} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography variant='h6'>Date</Typography>
        </Grid>
      </Grid>

      {
        isLoading ? (
          <ExistingEventsSkeleton />
        ) : (
          data?.results?.length != undefined && data?.results?.length > 0 ? (
            <>
              <List disablePadding={true} dense={true} >
                {data.results.map((d) => (
                  <ListItem key={d?.id} sx={{ width: '100%' }}>
                    <EventListItem data={d} key={d.id} />
                  </ListItem>
                ))}
              </List>

              {
                params.title
                  ? <PaginationTitle
                    page={page}
                    setPage={setPage}
                    pageRange={pageRange}
                  />
                  : <Pagination count={maxPages} page={page} onChange={handleChange} />
              }

            </>
          ) : (
            <Box sx={{ m: 5 }}>
              <Typography >No existing meetings found</Typography>

              <Box sx={{ padding: 2 }} />

              <Typography>See a <Link href="https://app.finmate.ai/public/events/4e7da38f-763c-4288-8e4c-bfad46496754">{'Sample Meeting'}</Link></Typography>

              {params.title &&
                <PaginationTitle
                  page={page}
                  setPage={setPage}
                  pageRange={pageRange}
                />
              }
            </Box>
          )
        )
      }

      <Box sx={{ padding: '5px' }} />
    </Card >
  )
}
