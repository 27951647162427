import React, { useEffect, useState } from 'react'
import { Box, SxProps, Card, Typography, IconButton, TextField, Button, Snackbar, Alert, DialogActions } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { ClientDetail } from '../../client'
import { gSx } from '../../styles/Theme'
import { logErr, logInfo } from '../../log'
import { upsertClient } from 'service/clientApi'
import { LoadingButton } from '@mui/lab'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  data?: ClientDetail
}

export default function ClientNotes({ data }: Props) {
  const queryClient = useQueryClient()
  const [isEdit, setEdit] = useState(false)
  const notes = data?.notes?.notes ?? ''
  const [newNotes, setNewNotes] = useState(notes)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [successSnack, setSuccessSnack] = useState(false)

  useEffect(() => {
    if (isEdit) {
      logInfo('ClientNotes Pressed Edit')
      setNewNotes(notes)
    }
  }, [isEdit])

  async function onSave() {
    setErr(undefined)
    setBusy(true)

    logInfo('ClientNotes Pressed Saved')

    if (!data) {
      setErr('missing client data')
      setBusy(false)
      return
    }

    const req: ClientDetail = {
      id: data.id,
      notes: {
        modified: (new Date()).toISOString(),
        notes: newNotes
      }
    }
    const res = await upsertClient(req)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getClientDetail', data.id] })
      setSuccessSnack(true)
      setErr(undefined)
      setEdit(false)
    }
    setBusy(false)
  }

  async function onCancel() {
    setNewNotes(notes)
    setEdit(false)
  }

  return (
    <>
      <Card sx={sxCard}>
        <Box sx={gSx.RowBetween}>
          <Typography variant='h4'>Client Notes</Typography>

          {
            !isEdit &&
            <IconButton onClick={() => setEdit(true)}>
              <EditIcon fontSize='small' />
            </IconButton>
          }

        </Box>

        <Box sx={{ padding: 1 }} />

        {
          isEdit ? (
            <>
              <TextField
                multiline
                minRows={5}
                maxRows={20}
                value={newNotes}
                onChange={(t) => {
                  setNewNotes(t.target.value)
                }}
                fullWidth
              />

              <DialogActions>
                {err && <Typography color='error.main'>{err}</Typography>}

                <Button
                  variant='outlined'
                  onClick={onCancel}
                  sx={sxButton}
                  disabled={busy}
                >
                  Cancel
                </Button>

                <LoadingButton
                  variant='contained'
                  onClick={onSave}
                  sx={sxButton}
                  disabled={busy}
                  loading={busy}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </>
          ) : (
            <Card sx={{ minHeight: 25 }}>
              <Typography>{notes}</Typography>
            </Card>
          )
        }

      </Card>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={successSnack}
        autoHideDuration={3000}
        onClose={() => setSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Client Notes Updated
        </Alert>

      </Snackbar>
    </>
  )
}

const sxCard: SxProps = {
  overflowY: 'auto',
  maxHeight: '83vh',
}

const sxButton: SxProps = {
  marginY: '2vh',
  width: '120px'
}