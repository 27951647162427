import React, { useEffect } from 'react'
import { Typography, Box, Container, Button, SxProps, Card } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import 'firebase/auth'
import { Path } from '../routes'
import { logInfo } from '../log'
import { useQuery } from '@tanstack/react-query'
import { CalendarSkeletons } from 'components/Skeletons'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { CalendarEvent } from 'client'
import CalendarEventSimple from 'components/calendar/CalendarEventSimple'
import { GetCalendarUser, ListCalendarMeetings } from 'service/calendarApi'
import { isCalendarConnected } from 'utils'
import { getRedtailSettings } from 'service/integrations/redtailApi'
import { gSx } from 'styles/Theme'
import CalendarConnectOptions from 'components/calendar/CalendarConnectOptions'
import SignInCalendarButton from 'components/calendar/SignInCalendarButton'

export default Calendar
function Calendar() {
  const navigate = useNavigate()

  useEffect(() => {
    logInfo('Calendar Screen')
  }, [])

  const { data: calUser, isLoading: isLoadingCal } = useQuery({
    queryKey: ['GetCalendarUser'], queryFn: GetCalendarUser,
  })
  const isCalConnected = isCalendarConnected(calUser)
  const { data, isLoading: isLoadingCalMeetings } = useQuery({
    queryKey: ['ListCalendarMeetings'], queryFn: ListCalendarMeetings
  })
  const { data: settings, isLoading: isLoadingRedtail } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings,
  })
  const isRedtailSignedIn = settings?.user_id ?? false
  const isLoading = isLoadingCalMeetings || isLoadingCal || isLoadingRedtail
  const isSignedIn = isCalConnected || isRedtailSignedIn

  useEffect(() => {
    logInfo('Calendar Screen')
    if (!isLoading && !isCalConnected && !isRedtailSignedIn) {
      navigate(Path.CalendarAuth)
    }
  }, [isLoading, isCalConnected])

  return (
    <Container>

      <Typography variant='h1' sx={{ padding: '2vh' }}>
        Calendar Events
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => navigate(Path.RecorderSettings)}>
          Recorder Settings
        </Button>
      </Box>

      {isLoading && <CalendarSkeletons />}

      {!isLoading && isSignedIn && (

        data?.length === 0 ? (
          <Typography sx={{ mt: 5 }}>No upcoming events</Typography>
        ) : (
          data?.map(event => {
            const date = new Date(event.start_time ?? '')
            const readable = date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' })
            return (
              <Box key={event.id}>
                {
                  ShowDateIfEarliest(date, data) &&
                  <Box sx={sxDate}>
                    <CalendarTodayIcon />
                    <Box sx={{ paddingX: '1vh' }} />
                    <Typography>{readable}</Typography>
                  </Box>
                }
                <CalendarEventSimple event={event} />
              </Box>
            )
          })
        )
      )}

    </Container>
  )
}

function ShowDateIfEarliest(date: Date, all: CalendarEvent[]) {
  const meetingsOftheDay = all.filter(m => (new Date(m.start_time ?? '')).toLocaleDateString() === date.toLocaleDateString())
  for (const m of meetingsOftheDay) {
    if (new Date(m.start_time ?? '') < date)
      return false
  }
  return true
}

const sxDate: SxProps = {
  paddingX: '2vh',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}
