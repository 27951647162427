import React from 'react'
import { Box, SxProps, Skeleton } from '@mui/material'
import { Path } from 'routes'
import { Media } from 'service/media'
import SideNavItem from './SideNavItem'
import { useQuery } from '@tanstack/react-query'
import { getSalesforceSettings } from 'service/integrations/salesforceApi'
import { SalesforceApp } from 'client'

interface Props {
  setOpen: (open: boolean) => void
}

export default function SideNavSalesforce({ setOpen }: Props) {

  const { data, isLoading } = useQuery({
    queryKey: ['getSalesforceSettings'],
    queryFn: getSalesforceSettings,
  })

  if (isLoading)
    return (
      <Skeleton
        variant="rectangular"
        width={130}
        height={40}
        sx={{ marginX: 2, marginY: 0.8 }}
      />
    )

  if (data?.app == SalesforceApp.PRACTIFI)
    return (
      <SideNavItem
        icon={<Box
          component="img"
          sx={sxIcon}
          src={Media.practifiIcon}
          alt="Practifi"
        />}
        path={Path.PractifiSettings}
        title={'Practifi'}
        setOpen={setOpen}
      />
    )

  if (data?.app == SalesforceApp.XLR8)
    return (
      <SideNavItem
        icon={<Box
          component="img"
          sx={sxIcon}
          src={Media.xlr8Icon}
          alt="Xlr8"
        />}
        path={Path.Xlr8Settings}
        title={'Xlr8'}
        setOpen={setOpen}
      />
    )

  return (
    <SideNavItem
      icon={<Box
        component="img"
        sx={sxIcon}
        src={Media.salesforceIcon}
        alt="Salesforce"
      />}
      path={Path.SalesforceSettings}
      title={'Salesforce'}
      setOpen={setOpen}
    />
  )
}

const sxIcon: SxProps = {
  width: 25,
  height: 25,
}