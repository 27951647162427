/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FirebaseUser } from '../models/FirebaseUser';
import type { LocationBase } from '../models/LocationBase';
import type { Permission } from '../models/Permission';
import type { SignupInfo } from '../models/SignupInfo';
import type { User } from '../models/User';
import type { UserConfigBase } from '../models/UserConfigBase';
import type { UserUsage } from '../models/UserUsage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Firebase User
     * @param targetUid
     * @returns FirebaseUser Successful Response
     * @throws ApiError
     */
    public getFirebaseUser(
        targetUid: string,
    ): CancelablePromise<FirebaseUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/firebase',
            query: {
                'target_uid': targetUid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User
     * @returns User Successful Response
     * @throws ApiError
     */
    public getUser(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/',
        });
    }

    /**
     * Disable User
     * @returns any Successful Response
     * @throws ApiError
     */
    public disableUser(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/',
        });
    }

    /**
     * Update Name
     * @param name
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateName(
        name: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/update_name',
            query: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save Location
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    public saveLocation(
        requestBody: LocationBase,
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/location',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save Affiliate Cid
     * @param affiliateCid
     * @param affiliateFinmateId
     * @returns any Successful Response
     * @throws ApiError
     */
    public saveAffiliateCid(
        affiliateCid: string,
        affiliateFinmateId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/affiliate/cid',
            query: {
                'affiliate_cid': affiliateCid,
                'affiliate_finmate_id': affiliateFinmateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Timezone
     * @param timezone
     * @returns User Successful Response
     * @throws ApiError
     */
    public updateTimezone(
        timezone: string,
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/location/timezone',
            query: {
                'timezone': timezone,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Signup Info
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateSignupInfo(
        requestBody: SignupInfo,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/signup/info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User Config
     * @returns UserConfigBase Successful Response
     * @throws ApiError
     */
    public getUserConfig(): CancelablePromise<UserConfigBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/configs',
        });
    }

    /**
     * Get User Config Bot Name
     * @returns any Successful Response
     * @throws ApiError
     */
    public getUserConfigBotName(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/configs/bot/name',
        });
    }

    /**
     * Update Bot Name
     * @param botName
     * @returns string Successful Response
     * @throws ApiError
     */
    public updateBotName(
        botName: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/configs/bot/name',
            query: {
                'bot_name': botName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Email Settings
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateEmailSettings(
        requestBody: UserConfigBase,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/configs/email_settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Zoom Auth Cred
     * @param zoomCode
     * @returns string Successful Response
     * @throws ApiError
     */
    public setZoomAuthCred(
        zoomCode: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/configs/zoom',
            query: {
                'zoom_code': zoomCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deauthorize Zoom
     * @returns any Successful Response
     * @throws ApiError
     */
    public deauthorizeZoom(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/configs/zoom',
        });
    }

    /**
     * Add Autoshare
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public addAutoshare(
        requestBody: Permission,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/autoshare/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Autoshare All
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateAutoshareAll(
        requestBody: Array<Permission>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/autoshare/all',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User Usage
     * @returns UserUsage Successful Response
     * @throws ApiError
     */
    public getUserUsage(): CancelablePromise<UserUsage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/usage',
        });
    }

}
