import React, { useState } from 'react'
import { EventBase, SalesforceApp } from '../../../client'
import { Box, ButtonBase, SxProps, Tooltip, Typography } from '@mui/material'
import { Media, salesforceAppIconUrl } from '../../../service/media'
import CheckIcon from '@mui/icons-material/Check'
import { useQuery } from '@tanstack/react-query'
import SalesforceDialog from './SalesforceDialog'
import { getSalesforceSettings } from 'service/integrations/salesforceApi'

interface Props {
  event?: EventBase
  eventSummaryHtml?: string
  disabled: boolean
}
export default function SalesforceEventButton({ event, eventSummaryHtml, disabled }: Props) {
  const [showDialog, setShowDialog] = useState(false)

  const { data } = useQuery({
    queryKey: ['getSalesforceSettings'],
    queryFn: getSalesforceSettings,
  })
  const app = data?.app ?? SalesforceApp.SALESFORCE
  const isSent = ((!!event?.salesforce_sent && app == SalesforceApp.SALESFORCE)
    || (!!event?.xlr8_sent && app == SalesforceApp.XLR8)
    || (!!event?.practifi_sent && app == SalesforceApp.PRACTIFI))

  const isSignedIn = !!data?.access_token

  if (!isSignedIn)
    return null

  return (
    <Box sx={{ paddingX: 1 }}>

      <Tooltip title={(isSent) ? `Summary sent to ${app}` : `Send to ${app}`}>
        <ButtonBase
          onClick={() => setShowDialog(true)}
          sx={disabled ? sxDisabledButton : sxButton}
          aria-label={app}
          disabled={disabled}
        >
          <Box
            component="img"
            sx={icon}
            src={salesforceAppIconUrl(app)}
            alt={app}
          />
          <Typography>{app}</Typography>
          {isSent &&
            <CheckIcon color='success' fontSize='small' sx={{ marginLeft: '5px' }} />
          }
        </ButtonBase>
      </Tooltip>

      <SalesforceDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        event={event}
        eventSummaryHtml={eventSummaryHtml}
        app={app}
      />
    </Box>
  )
}

const sxButton: SxProps = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  border: 1,
  borderRadius: 100,
  paddingY: 0.5,
  paddingX: 1.5,
  borderColor: 'black',
  '&:hover': {
    backgroundColor: 'MintCream'
  }
}

const sxDisabledButton: SxProps = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  border: 1,
  borderRadius: 100,
  paddingY: 0.5,
  paddingX: 1.5,
  borderColor: 'lightgray',
  opacity: 0.2
}

const icon: SxProps = {
  width: 20,
  height: 20,
  marginRight: '5px'
}