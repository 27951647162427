import React, { useState } from 'react'
import { EventBase } from '../../../client'
import { Box, ButtonBase, SxProps, Tooltip, Typography } from '@mui/material'
import { Media } from '../../../service/media'
import CheckIcon from '@mui/icons-material/Check'
import WealthboxDialog from './WealthboxDialog'
import { useQuery } from '@tanstack/react-query'
import { getWealthboxProfile } from 'service/integrations/wealthboxApi'

interface Props {
  event?: EventBase
  eventSummaryHtml?: string
  disabled: boolean
}
export default function WealthboxEventButton({ event, eventSummaryHtml, disabled }: Props) {
  const isSent = event?.wealthbox_sent ?? false
  const [showDialog, setShowDialog] = useState(false)

  const { data } = useQuery({
    queryKey: ['getWealthboxProfile'],
    queryFn: getWealthboxProfile,
  })

  const isSignedIn = data ?? false

  if (!isSignedIn)
    return null

  return (
    <Box sx={{ paddingX: 1 }}>
      <Tooltip title={isSent ? "Summary sent to Wealthbox" : 'Send to Wealthbox'}>
        <ButtonBase
          onClick={() => setShowDialog(true)}
          sx={disabled ? sxDisabledButton : sxButton}
          aria-label={'Wealthbox'}
          disabled={disabled}
        >
          <Box
            component="img"
            sx={icon}
            src={Media.wealthboxIcon}
            alt="Wealthbox"
          />
          <Typography>Wealthbox</Typography>
          {isSent &&
            <CheckIcon color='success' fontSize='small' sx={{ marginLeft: '5px' }} />
          }
        </ButtonBase>
      </Tooltip>

      <WealthboxDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        event={event}
        eventSummaryHtml={eventSummaryHtml}
      />
    </Box>
  )
}

const sxButton: SxProps = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  border: 1,
  borderRadius: 100,
  paddingY: 0.5,
  paddingX: 1.5,
  borderColor: 'black',
  '&:hover': {
    backgroundColor: 'MintCream'
  }
}

const sxDisabledButton: SxProps = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  border: 1,
  borderRadius: 100,
  paddingY: 0.5,
  paddingX: 1.5,
  borderColor: 'lightgray',
  opacity: 0.2
}

const icon: SxProps = {
  width: 20,
  height: 20,
  marginRight: '5px'
}