import React, { useState } from 'react'
import {
  Box,
  SxProps,
  Typography,
  IconButton,
  Button,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  DialogActions,
  Card,
  Checkbox,
} from '@mui/material'
import { Access, EventBase, OrgUser, Permission } from '../../../client'
import { gColor, gSx } from '../../../styles/Theme'
import { logErr, logInfo } from '../../../log'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { addEventShare } from 'service/eventApi'
import { LoadingButton } from '@mui/lab'
import { useQuery } from '@tanstack/react-query'
import { getOrg } from 'service/orgsApi'
import ProfilePhoto from 'components/settings/ProfilePhoto'

interface Props {
  data?: EventBase
  input: OrgUser
  onShow: (isShow: boolean) => void
  onClose: () => void
  disabled?: boolean
  setOpenSuccessSnack: (isOpen: boolean) => void
}

export default function ShareEventAddAccess({ input, disabled, data, onShow, onClose, setOpenSuccessSnack }: Props) {
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [notify, setNotify] = useState(true)
  const [access, setAccess] = useState<Access>(Access.VIEWER)
  const nameEmail = input?.name ? `${input?.name}, ${input?.email}` : `${input?.email}`

  function onChangeNotify(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    setNotify(checked)
  }
  async function onSend() {
    setBusy(true)
    setErr(undefined)
    const p: Permission = {
      uid: input.uid ?? '',
      name: input.name,
      email: input.email,
      photo_url: input.photo_url,
      access: access
    }
    logInfo('Event Manual Share. Pressed Send Button', { permission: p })
    const isSuccess = await addEventShare(p, notify, data?.id)
    if (isSuccess) {
      setOpenSuccessSnack(true)
      onClose()
    }
    else
      setErr('Something went wrong, try again later.')

    setBusy(false)
  }

  return (
    <>
      <Box sx={gSx.RowBetween}>
        <Box sx={{ ...gSx.Row, marginLeft: 2 }}>
          <IconButton onClick={() => onShow(false)}>
            <ArrowBackIcon />
          </IconButton>
          <DialogTitle>Share with User</DialogTitle>
        </Box>

        <IconButton sx={sxClose} aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>

        <Card sx={gSx.RowBetween}>
          <Box sx={gSx.Row}>
            <Box sx={{ width: 50 }}>
              <ProfilePhoto photoUrl={input.photo_url} width={40} />
            </Box>
            <Box sx={gSx.Row}>
              <Typography>{nameEmail}</Typography>
            </Box>
          </Box>

          <FormControl sx={{ width: 150 }}>
            <Select
              id="access"
              value={access}
              onChange={e => setAccess(e.target.value as Access)}
              disabled={disabled}
            >
              <MenuItem value={Access.VIEWER}>{Access.VIEWER}</MenuItem>
              <MenuItem value={Access.EDITOR}>{Access.EDITOR}</MenuItem>
            </Select>
          </FormControl>

        </Card>

        <Box sx={gSx.Row}>
          <Checkbox aria-label='notify checkbox' checked={notify} onChange={onChangeNotify} />
          <Typography>Notify via email?</Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => onShow(false)}
          sx={{ width: 110 }}
          disabled={busy}
        >
          cancel
        </Button>

        <LoadingButton
          onClick={onSend} variant='contained'
          sx={{ width: 110 }}
          loading={busy}
          disabled={busy || disabled}
        >
          Send
        </LoadingButton>
      </DialogActions>

      {
        (status || err) &&
        <DialogActions>
          {err && <Typography sx={{ color: 'tomato' }}>{err}</Typography>}
          {status && <Typography sx={{ color: gColor.green500 }}>{status}</Typography>}
        </DialogActions>
      }

    </>
  )
}

const sxClose: SxProps = {
  alignSelf: 'flex-start',
}
