import { CalendarUser, OrgBase, StatusChanges, SummaryDetailBase } from 'client'
import _ from 'lodash'
export * from './error'
export * from './date'
import { auth } from 'service/api'
import { convert } from 'html-to-text'

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function capitalizeEveryWord(text: string) {
  return text.replace(/\b\w/g, (match) => match.toUpperCase())
}

export function isImageURLReal(imageURL?: string | null): Promise<boolean> {
  if (!imageURL)
    return Promise.resolve(false)

  return new Promise(function (resolve) {
    const img = new Image()

    img.onload = function () {
      resolve(true)
    }

    img.onerror = function () {
      resolve(false)
    }
    img.src = imageURL
  })
}

export function isEmailValid(email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(email)
}

export function flattenDetails(details?: SummaryDetailBase[]): string {
  if (!details)
    return ''

  const res: string[] = []
  for (const detail of details) {
    res.push(`<br><p><b>${detail.category}</b></p>`)

    if (detail.summary_items) {
      res.push('<div><ul>')
      for (const point of detail.summary_items) {
        res.push(`<li>${point.text}</li>`)
      }
      res.push('</ul></div>')
    }

  }

  return res.join('\n')
}

export function latestBotStatus(statuses?: StatusChanges[]) {
  if (!statuses)
    return
  return statuses[statuses.length - 1].code
}

export function isObjectEmpty(obj: object): boolean {
  return Object.keys(obj).length === 0
}

export function isFirebaseUserGoogle() {
  const fbUser = auth.currentUser
  // uncommon case, sign in with apple with gmail. providerId's can be 2
  const isGoogle = _.some(fbUser?.providerData, p => p.providerId === 'google.com')
  if (isGoogle)
    return true

  const appleArr = _.filter(fbUser?.providerData, p => p.providerId === 'apple.com')
  if (appleArr.length > 0) {
    const apple = appleArr[0]
    if (apple.email?.includes('gmail.com'))
      return true
  }
  return false
}

export function isFirebaseUserMicrosoft() {
  const fbUser = auth.currentUser
  // uncommon case, sign in with apple with outlook. providerId's can be 2
  const isGoogle = _.some(fbUser?.providerData, p => p.providerId === 'microsoft.com')
  if (isGoogle)
    return true

  const appleArr = _.filter(fbUser?.providerData, p => p.providerId === 'apple.com')
  if (appleArr.length > 0) {
    const apple = appleArr[0]
    if (apple.email?.includes('outlook.com') || apple.email?.includes('hotmail.com'))
      return true
  }
  return false
}

export function getFileExtension(filename: string): string {
  const parts: string[] = filename.split('.')
  if (parts.length > 1) {
    return parts[parts.length - 1]
  } else {
    return ''
  }
}

export function isCalendarConnected(cal?: CalendarUser) {
  if (!cal)
    return false

  if (cal.connections) {
    for (const c of cal.connections) {
      if (c.connected)
        return true
    }
  }

  return false
}

export function getDomainFromEmail(email?: string | null) {
  const parts = email?.split('@')
  return parts && parts.length > 1 ? parts[parts.length - 1] : null
}

export function isValidDomain(email2: string, org?: OrgBase): boolean {
  if (!org || !org.domains)
    return false

  const domainCheck = getDomainFromEmail(email2)
  for (const valid of org.domains) {
    if (valid == domainCheck)
      return true
  }
  return false
}

export function listValidDomains(org: OrgBase) {
  const validDomains = []
  if (org?.domains) {
    for (const d of org.domains)
      validDomains.push(`@${d}`)
    return validDomains.join(', ')
  }
  return ''
}

export function isStringArraysEqual(array1: string[], array2: string[]) {
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false
    }
  }
  return true
}

export function htmlToPlainText(html: string, useDash = false) {
  html = html.replaceAll(/<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi, "<li>$1<$2$3>")

  let listHeading = '  • '
  if (useDash) {
    listHeading = '  - '
  }

  const plainText = convert(html, {
    wordwrap: false, selectors: [
      { selector: 'h3', options: { uppercase: false } },
      { selector: 'ul', options: { itemPrefix: listHeading } }]
  })
  return plainText
}

export function getInitial(inputString: string | undefined): string {
  if (inputString == undefined)
    return ''
  inputString = inputString.trim()
  const words = inputString.split(' ')
  if (words.length === 0) {
    return ''
  }
  let initial = ""
  if (typeof words[0][0] == "string" && words[0][0].length > 0) {
    initial = words[0][0].toUpperCase()
  }
  if (words.length > 2 && typeof words[1][0] == "string" && words[1][0].length > 0) {
    initial = initial + words[1][0].toUpperCase()
  }
  return initial
}

export function createGmailComposeURL(to: string, subject: string, body: string): string {
  const base = 'https://mail.google.com/mail/?view=cm&fs=1&tf=1'
  const encodedTo = encodeURIComponent(to)
  const encodedSubject = encodeURIComponent(subject)
  const encodedBody = encodeURIComponent(body)

  return `${base}&to=${encodedTo}&su=${encodedSubject}&body=${encodedBody}`
}

export function createOutlookComposeURL(to: string, subject: string, body: string): string {
  const base = 'https://outlook.live.com/owa/?path=/mail/action/compose&to='
  const encodedTo = encodeURIComponent(to)
  const encodedSubject = encodeURIComponent(subject)
  const encodedBody = encodeURIComponent(body)

  return `${base}${encodedTo}&subject=${encodedSubject}&body=${encodedBody}`
}