import React, { useEffect, useState } from 'react'
import { Box, Card, FormControlLabel, IconButton, Radio, RadioGroup, Skeleton, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { EventBase, WealthboxType } from 'client'
import { gColor, gSx } from 'styles/Theme'
import { useQuery } from '@tanstack/react-query'
import { getWealthboxContact } from 'service/integrations/wealthboxApi'
import ClientEventDialog from 'components/client/ClientCardForEventDialog'
import EditIcon from '@mui/icons-material/Edit'

interface Props {
  event?: EventBase
  wbType: WealthboxType
  setWbType: (who: WealthboxType) => void
  disabled: boolean
}

export default function WealthboxClientCard({ event, wbType, setWbType, disabled }: Props) {
  const clientEmail = event?.client?.email
  const [showAddClientDialog, setShowAddClientDialog] = useState(false)

  const { data, isLoading } = useQuery({
    queryKey: ['getWealthboxContact', clientEmail],
    queryFn: async () => await getWealthboxContact(clientEmail)
  })
  const noHousehold = !data?.household?.id

  useEffect(() => {
    if (noHousehold) {
      setWbType(WealthboxType.PERSON)
    }
  }, [data])

  return (
    <Card>
      <Typography variant='h5'>Select Contact</Typography>
      <Typography>Meeting Summary and Tasks will be saved under Contact either as a Person or a Household</Typography>
      <Box sx={{ padding: 1 }} />

      <RadioGroup
        aria-label="contact type"
        name="select contact type"
        value={wbType}
        onChange={(event => setWbType(event.target.value as WealthboxType))}
      >

        <Grid container >
          <Grid xs={1}>
            <FormControlLabel
              value={WealthboxType.PERSON}
              control={<Radio />}
              label=""
              sx={{ margin: 0 }}
              disabled={disabled}
            />
          </Grid>

          <Grid xs={3}>
            <Box sx={{ padding: 0.5 }} />
            <Typography variant='h5'>Person</Typography>
          </Grid>

          <Grid xs={8}>
            <Box sx={{ padding: 0.5 }} />
            <Typography color={gColor.green500}>
              {event?.client?.first_name ?? ''} {event?.client?.last_name ?? ''}
            </Typography>
            <Typography color={gColor.green500}>{event?.client?.email ?? ''}</Typography>

            {!clientEmail &&
              <>
                <Box sx={gSx.Row}>
                  <Typography sx={{ color: 'red', cursor: 'pointer', fontWeight: 'bold' }}>
                    Add a client
                  </Typography>
                  <IconButton
                    aria-label='edit'
                    onClick={() => setShowAddClientDialog(true)}
                    disabled={disabled}
                  >
                    <EditIcon fontSize='small' />
                  </IconButton>
                </Box>

                <Typography >
                  The Email should match your existing CRM contact, or Finmate will automatically create a new CRM contact for you.
                </Typography>
              </>
            }
            <ClientEventDialog
              event={event}
              open={showAddClientDialog}
              onClose={() => setShowAddClientDialog(false)}
            />
          </Grid>
        </Grid>

        <Box sx={{ padding: 1 }} />
        <Grid container>
          <Grid xs={1}>
            <FormControlLabel
              value={WealthboxType.HOUSEHOLD}
              control={<Radio />}
              label=""
              disabled={isLoading || noHousehold || disabled}
              sx={{ margin: 0 }}
            />
          </Grid>

          <Grid xs={3}>
            <Box sx={{ padding: 0.5 }} />
            <Typography variant='h5' color={noHousehold ? 'lightgray' : 'black'}>Household</Typography>
          </Grid>

          <Grid xs={8}>
            {
              isLoading
                ? <Skeleton variant="rectangular" width={'90%'} height={20} />
                : noHousehold
                  ? <Typography sx={{ paddingTop: 1 }}>No Wealthbox Household Found</Typography>
                  : (
                    <>
                      <Box sx={{ padding: 0.5 }} />
                      <Typography color={gColor.green500} variant='h6'>
                        {data?.household?.name}
                      </Typography>
                      {data.household?.members?.map(m => (
                        <Typography key={m.id} color={gColor.green500}>
                          {`${m.first_name} ${m.last_name} (${m.title})`}
                        </Typography>
                      ))}
                    </>
                  )

            }
          </Grid>
        </Grid>
      </RadioGroup>

    </Card>
  )
}
