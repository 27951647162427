/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Origin {
    WEB_UPLOAD = 'Web Upload',
    WEB_RECORDER = 'Web Recorder',
    IOS_RECORDER = 'ios_recorder',
    ANDROID_RECORDER = 'Android Recorder',
}
