import React, { useState } from 'react'
import { Avatar, Box, Chip, SxProps, Tooltip } from '@mui/material'
import { AssigneeInfo, CRM, SalesforceApp } from '../../client'
import { getInitial } from 'utils'
import CrmAssigneePopover from './CrmAssigneePopover'
import { Media } from 'service/media'
import { useQuery } from '@tanstack/react-query'
import { getSalesforceIcon, getSalesforceSettings } from 'service/integrations/salesforceApi'

interface Props {
  assigneeInfo?: AssigneeInfo
  allAssignees: AssigneeInfo[]
  onChangeAssignee: (assignee: AssigneeInfo) => void
  crm: CRM
  disabled: boolean
}

export default function CrmAssigneeChip({ assigneeInfo, allAssignees, onChangeAssignee, crm, disabled }: Props) {
  const [showPopover, setShowPopover] = useState<HTMLButtonElement | null>(null)

  const { data: salesforceSettings } = useQuery({
    queryKey: ['getSalesforceSettings'],
    queryFn: getSalesforceSettings,
    enabled: false,
  })
  const salesforceApp = salesforceSettings?.app ?? SalesforceApp.SALESFORCE

  const handleAssigneeClickChip = (event: any) => {
    setShowPopover(event.currentTarget)
  }

  function getAvatar() {
    if (crm == CRM.REDTAIL && assigneeInfo?.redtail_user_id) {
      return <Box
        component="img"
        sx={sxIcon}
        src={Media.redtailIconWhite}
        alt="Redtail"
      />
    }

    if (crm == CRM.WEALTHBOX && assigneeInfo?.wealthbox_user_id) {
      return <Box
        component="img"
        sx={sxIcon}
        src={Media.wealthboxIcon}
        alt="Wealthbox"
      />
    }

    if (crm == CRM.SALESFORCE && assigneeInfo?.salesforce_user_id) {
      return <Box
        component="img"
        sx={sxIcon}
        src={getSalesforceIcon(salesforceApp)}
        alt={salesforceApp}
      />
    }

    return <Avatar>{getInitial(assigneeInfo?.name)}</Avatar>
  }

  return (
    <>
      <Tooltip title={`Assign ${crm} User`}>
        <Chip
          label={assigneeInfo?.name}
          avatar={getAvatar()}
          variant="outlined"
          sx={sxSpeakerChip}
          onClick={(event: any) => handleAssigneeClickChip(event)}
          disabled={disabled}
        />
      </Tooltip>

      <CrmAssigneePopover
        showPopover={showPopover}
        closePopover={() => setShowPopover(null)}
        onChangeAssignee={onChangeAssignee}
        allAssignees={allAssignees}
        crm={crm}
      />
    </>
  )
}

const sxSpeakerChip: SxProps = {
  fontSize: 12,
  fontWeight: 'bold',
  cursor: 'pointer',
  '&:hover': {
    color: "primary.main"
  }
}

const sxIcon: SxProps = {
  marginLeft: 1,
  width: 25,
  height: 25,
}