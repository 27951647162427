import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@mui/system'
import { Container, Divider, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch, Typography } from '@mui/material'
import { logInfo } from 'log'
import { gSx } from 'styles/Theme'
import { useStore } from 'hooks/useStore'
import { isSuperUser } from 'service/authApi'
import Usage from 'components/settings/Usage'
import ProfileSettingsCard from 'components/settings/ProfileSettingsCard'
import HelpIcon from '@mui/icons-material/Help'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import EmailSettingsCard from 'components/settings/EmailSettingsCard'

export default observer(Settings)
function Settings() {
  const store = useStore()
  const showAdmin = store.Security.getShowAdmin()

  useEffect(() => {
    logInfo('Settings Screen')
  }, [])

  async function onSwitch(switchElement: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    store.Security.setShowAdmin(checked)
  }

  return (
    <Container>

      <Typography variant='h1' sx={{ paddingY: '5vh' }} >General</Typography>

      <ProfileSettingsCard />

      <EmailSettingsCard />

      <Usage />

      <List>
        <Link
          rel='noopener'
          underline="none"
          target='_blank'
          href={'https://www.finmate.ai/help'}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon> <HelpIcon /> </ListItemIcon>
              <ListItemText primary="Help" />
              <ListItemText primary="Frequently Asked Questions" />
              <ChevronRightIcon />
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />

        <Link
          rel='noopener'
          underline="none"
          target='_blank'
          href={'mailto:sales@finmate.ai'}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon> <EmojiPeopleIcon />  </ListItemIcon>
              <ListItemText primary="Refer and Earn" />
              <ListItemText primary="Earn by Referring. Contact sales@finmate.ai" />

            </ListItemButton>
          </ListItem>
        </Link>

      </List>

      <Box sx={{ paddingY: '3vh' }} />

      {
        isSuperUser() && (
          <Box sx={gSx.Row}>
            <Typography>Show admin. Visible only to @trellish or @finmate.ai</Typography>
            <Switch
              checked={showAdmin}
              onChange={onSwitch}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        )
      }
    </Container >
  )
}
