import React, { useEffect, useState } from 'react'
import { Card, Typography, Box, Button, Snackbar, Alert, LinearProgress } from '@mui/material'
import { getUser, getUserConfig, updateAutoshareAll } from '../../../service'
import { logInfo } from '../../../log'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { gSx } from 'styles/Theme'
import CheckIcon from '@mui/icons-material/Check'
import { Permission } from 'client'
import Grid from '@mui/material/Unstable_Grid2'
import AutoShareDialog from './AddAutoShareDialog'
import UserAccessLineItem from './UserAccessLineItem'
import { LoadingButton } from '@mui/lab'
import { isStringArraysEqual } from 'utils'
import PersonAddIcon from '@mui/icons-material/PersonAdd'

export default function AutoShareEventsCard() {
  const queryClient = useQueryClient()
  const [openDialog, setOpenDialog] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)

  const { data: userConfig, isLoading, refetch } = useQuery({
    queryKey: ['getUserConfig'], queryFn: getUserConfig,
  })
  const permissions = userConfig?.auto_share ?? []

  async function onSave(target: Permission[]) {
    logInfo('Share Event, modify user permissions. Save button pressed.', target)
    setErr(undefined)
    setBusy(true)
    const res = await updateAutoshareAll(target)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getUserConfig'] })
      setSnackSuccess(true)
    }
    setBusy(false)
  }

  return (
    <Card>
      <Box sx={gSx.RowBetween}>
        <Box>
          <Typography variant='h5'>Auto Share Meetings</Typography>
          <Typography>
            Share future meetings with selected users such as your assistant.
          </Typography>
        </Box>

        <Button
          variant='outlined'
          onClick={() => setOpenDialog(true)}
        >
          <PersonAddIcon />
        </Button>
      </Box>

      <Box sx={{ padding: 2 }} />

      <Grid container spacing={2}>
        {permissions?.map(p =>
          <UserAccessLineItem
            key={p.uid}
            permission={p}
            disabled={false}
            allPermissions={permissions}
            onSave={onSave}
          />
        )}
      </Grid>

      <Box sx={{ padding: 2 }} />

      {busy && <LinearProgress />}
      {err && <Typography color={'tomato'} >{err}</Typography>}

      <AutoShareDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Auto Share Permissions Sucessfully Updated
        </Alert>
      </Snackbar>

    </Card>

  )
}
