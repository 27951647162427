import React, { useEffect, useState } from 'react'
import { Box, SxProps, Card, Typography, Switch, Snackbar, Alert, Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getUserConfig, updateEmailSettings } from 'service'
import { gSx } from 'styles/Theme'

export default function EmailSettingsCard() {
  const [busy, setBusy] = useState(false)
  const [emailTranscriptDone, setEmailTranscriptDone] = useState(true)
  const [emailSummaryDone, setEmailSummaryDone] = useState(false)
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const { data, isLoading } = useQuery({
    queryKey: ['getUserConfig'],
    queryFn: getUserConfig,
  })

  useEffect(() => {
    if (!isLoading && data) {
      setEmailTranscriptDone(data.email_transcript_done ?? true)
      setEmailSummaryDone(data.email_summary_done ?? false)
    }
  }, [isLoading])

  async function onTranscriptDone(state: boolean) {
    setBusy(true)
    setEmailTranscriptDone(state)
    const res = await updateEmailSettings({ email_transcript_done: state })
    if (res instanceof Error)
      setErr(res.message)
    else
      setOpenSuccessSnack(true)
    setBusy(false)
  }

  async function onSummaryDone(state: boolean) {
    setBusy(true)
    setEmailSummaryDone(state)
    const res = await updateEmailSettings({ email_summary_done: state })
    if (res instanceof Error)
      setErr(res.message)
    else
      setOpenSuccessSnack(true)
    setBusy(false)
  }

  return (
    <Card>
      <Typography variant='h4'>Email Settings</Typography>

      <Box sx={{ paddingY: 1 }} />

      {
        isLoading
          ? <>
            <Skeleton variant="rectangular" width={'90%'} height={20} />
            <Skeleton variant="rectangular" width={'90%'} height={20} />
          </>
          : <>
            <Box sx={gSx.Row}>
              <Switch
                checked={emailTranscriptDone}
                onChange={(event, checked) => onTranscriptDone(checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={busy}
              />
              <Box>
                <Typography variant='h6'>Meeting Transcript Ready Email</Typography>
                <Typography>Receive an email notification when the transcript has been processed and is ready for you to choose the meeting summary type.</Typography>
              </Box>
            </Box>

            <Box sx={{ paddingY: 1 }} />

            <Box sx={gSx.Row}>
              <Switch
                checked={emailSummaryDone}
                onChange={(event, checked) => onSummaryDone(checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={busy}
              />
              <Box>
                <Box>
                  <Typography variant='h6'>Meeting Summary Finished Email</Typography>
                  <Typography>Get an email notification once the meeting summary has finished processing and is available for review.</Typography>
                </Box>
              </Box>
            </Box>
          </>
      }

      <Box sx={{ paddingY: 1 }} />

      {err && <Typography sx={{ color: 'tomato' }}>{err}</Typography>}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Email Settings Updated
        </Alert>

      </Snackbar>
    </Card >
  )
}
