import { createTheme, SxProps } from '@mui/material'

// https://mui.com/material-ui/customization/default-theme/

declare module '@mui/material/styles' {
  interface PaletteColor {
    100?: string
    200?: string
    300?: string
    700?: string
    800?: string
    900?: string
  }
  interface SimplePaletteColorOptions {
    100?: string
    200?: string
    300?: string
    700?: string
    800?: string
    900?: string
  }
}

export const gColor = {
  white: '#fbfeff',
  backgroundColor: '#f1f4f6',
  light: '#45CBDC',
  green500: '#349ead',
  successBanner: '#F5FFFA',
  errorBanner: 'lavenderblush'
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: 32,
      fontWeight: 300,
    },
    h2: {
      fontSize: 28,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 24,

    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
      paddingY: '10px',
    },
    h5: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    h6: {
      // same as regular text but bold
      fontSize: 15,
      fontWeight: 'bold',
    },
    body2: {
      fontSize: 15,
    },
  },
  palette: {
    primary: {
      // blues
      main: '#349ead',
      900: '#0A1929',
    },
    background: {
      default: gColor.backgroundColor,
      paper: gColor.backgroundColor,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          padding: '10px',
          borderRadius: '10px',
          marginBottom: '2vh',
          boxShadow: 'none',
          borderColor: gColor.backgroundColor,
          borderWidth: '2px',
          borderStyle: 'solid'
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px'
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          marginBottom: '3px'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 0, // for small screens, remove margins.

        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '20px', // Set the default font size for DialogTitle
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          marginRight: '15px',
          marginBottom: '15px',
        },
      },
    },
  },

})

export const gSx = {
  RowBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as SxProps,
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  } as SxProps,
  CenterPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vh',
    height: '100vh',
  } as SxProps,
  CenterVertical: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
}

// beyond headers, and body.
export const gSxText = {
  small: {
    fontSize: 14,
  } as SxProps,
}
