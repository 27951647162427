import React, { useEffect } from 'react'
import { Card, Box, Typography, SxProps, Skeleton, Container, Button } from '@mui/material'
import AutoRecord from '../../components/settings/AutoRecord'
import { useQuery } from '@tanstack/react-query'
import { GetCalendarUser } from '../../service/calendarApi'
import RecorderAppearance from '../../components/settings/RecorderAppearance'
import ConnectedCalendar from '../../components/settings/ConnectedCalendar'
import { observer } from 'mobx-react-lite'
import { logInfo } from '../../log'
import ZoomSettings from '../../components/settings/ZoomCardForRecorder'
import { isCalendarConnected } from 'utils'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import AutoShareEventsCard from 'components/event/shareEvent/AutoShareEventsCard'
import ContainerMedium from 'components/ContainerMedium'

export default observer(RecorderSettings)
function RecorderSettings() {
  const navigate = useNavigate()
  useEffect(() => {
    logInfo('Recorder Settings Screen')
  }, [])

  const { data, isLoading } = useQuery({
    queryKey: ['GetCalendarUser'],
    queryFn: async () => await GetCalendarUser(),
  })
  const isCalConnected = isCalendarConnected(data)

  const skeletonHeight = 130
  return (
    <ContainerMedium>
      <Typography variant='h1' sx={{ paddingY: '5vh' }} >Recorder</Typography>

      {!isCalConnected ? (
        <Card sx={sxCard}>
          <Typography variant='h5'>
            Calendar Disconnected
          </Typography>
          <Typography sx={{ color: 'tomato' }}>
            Connect your calendar to enable auto recording and other features
          </Typography>
          <Box sx={{ padding: 1 }} />

          <Box sx={sxMid}>
            <Button variant='outlined' onClick={() => navigate(Path.CalendarAuth)}>
              Calendar Settings
            </Button>
          </Box>

        </Card>
      ) : isLoading ? (
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      ) : (
        <ConnectedCalendar />
      )}

      <ZoomSettings />
      <AutoRecord isCalendarConnected={isCalConnected} />
      <RecorderAppearance />
      <AutoShareEventsCard />

    </ContainerMedium>
  )
}

const sxCard: SxProps = {
  display: 'flex',
  flexDirection: 'column',
}

const sxMid: SxProps = {
  display: 'flex',
  justifyContent: 'center',
}
