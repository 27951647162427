import React from 'react'
import { Typography, SxProps, Box, Card, Container, List, ListItem } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { gSx } from '../../styles/Theme'
import ClientListItemButton from '../../components/client/ClientListItemButton'
import Grid from '@mui/material/Unstable_Grid2'
import { getUserClients } from 'service/clientApi'
import AddClientDialog from './AddClientDialog'
import { CalendarSkeletons } from 'components/Skeletons'
import ImportContactsListButtons from 'components/integrations/ImportContactsListButtons'

export default function ClientsScreen() {

  const { data: clients, isLoading } = useQuery({
    queryKey: ['getUserClients'], queryFn: getUserClients
  })

  return (
    <Container>
      <Box sx={gSx.RowBetween}>
        <Typography variant='h1' sx={{ padding: '2vh' }}>Clients</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>

          <AddClientDialog />
          <ImportContactsListButtons />

        </Box >
      </Box >

      <Box sx={{ padding: '1vh' }} />

      <Grid container spacing={3} sx={{ paddingLeft: '40px', paddingRight: '30px' }}>
        <Grid xs={4}>
          <Typography variant='h6' ml={1}>
            Name
          </Typography>
        </Grid>

        <Grid xs={4}>
          <Typography variant='h6'>Email</Typography>
        </Grid>

        <Grid xs={4}>
          <Typography variant='h6'>Latest Meeting</Typography>
        </Grid>
      </Grid>

      {
        (isLoading || !clients)
          ? <CalendarSkeletons />
          : (
            <Card sx={sxCard}>
              {clients.length > 0 ? (
                <List disablePadding={true} dense={true} >
                  {clients.map((client) => (
                    <ListItem key={client.id} sx={{ width: '100%' }}>
                      <ClientListItemButton data={client} key={client.id} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography>No existing clients</Typography>
              )}
            </Card>
          )
      }
    </Container >
  )
}

const sxCard: SxProps = {
  cursor: 'pointer',
}
