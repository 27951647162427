import React, { useEffect, useState } from 'react'
import { Box, Button, Card, SxProps, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { answerOrgInvite, getOrgInvitesAsRecipient } from 'service/orgsApi'
import { logInfo } from 'log'
import { LoadingButton } from '@mui/lab'
import { OrgInvite, OrgUserStatus } from 'client'
import { useWindowSize } from 'react-use'
import Confetti from 'react-confetti'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { getSubscription } from 'service/payApi'

export default function ReceivedInviteScreen() {
  const [busy, setBusy] = useState(false)
  const [resInvite, setResInvite] = useState<OrgInvite | undefined>()
  const { width, height } = useWindowSize()
  const navigate = useNavigate()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getOrgInvitesAsRecipient'],
    queryFn: async () => await getOrgInvitesAsRecipient(),
  })

  const { data: sub } = useQuery({
    queryKey: ['getSubscription'],
    queryFn: getSubscription,
  })
  const isOrgSubscribed = !!sub?.subscription

  useEffect(() => {
    logInfo('Received Invite Screen')
  }, [])

  async function onAnswer(invite: OrgInvite, newStatus: OrgUserStatus) {
    setBusy(true)
    const res = await answerOrgInvite(invite.id ?? '', newStatus)
    setResInvite(res)
    await refetch()
    setBusy(false)
  }

  if (isLoading || !data)
    return null

  if (data.length > 0)
    return (
      <Box sx={sxCenter}>
        {
          data.map(invite => (
            <Card key={invite.id} sx={{ width: 400 }}>
              <Typography>
                {`Invitation to join`}</Typography>
              <Typography variant='h3' textAlign={'center'}>
                {`${invite.org_name}`}</Typography>
              <Box sx={{ padding: 1 }} />
              <Typography>{`${invite.owner_name ?? 'This person'} (${invite.owner_email}), has invited you to join ${invite.org_name}`}</Typography>
              <Box sx={{ padding: 1 }} />

              <Box sx={sxAction}>
                <LoadingButton
                  variant='contained'
                  onClick={() => onAnswer(invite, OrgUserStatus.ACCEPTED)}
                  loading={busy}
                  disabled={isOrgSubscribed}
                >
                  Accept
                </LoadingButton>
                <Box sx={{ paddingX: 3 }} />
                <LoadingButton
                  loading={busy}
                  variant='outlined'
                  onClick={() => onAnswer(invite, OrgUserStatus.DECLINED)}
                >
                  Decline
                </LoadingButton>
              </Box>
            </Card>
          ))
        }

        {
          data && data?.length > 1 &&
          <Typography variant="body2">
            By accepting an Invitation, other invitations will automatically decline
          </Typography>
        }

        {
          isOrgSubscribed && data && data.length >= 1 &&
          <Typography variant="body2">
            To accept the invitation, please contact customer support
          </Typography>
          //  Need to unsubscribe user, in order to transfer
        }
      </Box>
    )

  if (resInvite && resInvite.status == OrgUserStatus.ACCEPTED)
    return (
      <Box sx={sxCenter}>
        <Typography>You have successfully joined</Typography>
        <Box sx={{ padding: 1 }} />
        <Typography variant='h3'>{resInvite.org_name}</Typography>
        <Box sx={{ padding: 3 }} />
        <Button variant='outlined' onClick={() => navigate(Path.Home)}>
          Dashboard
        </Button>
        <Confetti
          width={width}
          height={height}
        />
      </Box>
    )

  return (
    <Box sx={sxCenter}>
      <Typography>
        No invitations to see
      </Typography>
      <Button variant='outlined' onClick={() => navigate(Path.Home)}>
        Dashboard
      </Button>
    </Box>
  )
}

const sxAction: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}

const sxCenter: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh'
}