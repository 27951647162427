import { Access, FirebaseUser, LocationBase, OrgUser, Permission, SignupInfo, User, UserConfigBase, UserUsage } from '../client'
import { auth, Api } from './api'
import { logErr, logInfo } from '../log'
import { signOut } from 'firebase/auth'
import { getErrorMessage } from 'utils'

export async function getFirebaseUser(targetUid: string): Promise<FirebaseUser | undefined> {
  try {
    const api = await Api()
    return await api.users.getFirebaseUser(targetUid)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('getFirebaseUser', { err })
  }
}

export async function getUser(): Promise<User | undefined> {
  try {
    const api = await Api()
    return await api.users.getUser()
  } catch (e: any) {
    logErr('getUser', { error: e.message })
    if (e.message == 'Unauthorized') {
      await signOut(auth) // not triggered by public, ok to signout
    }
  }
}

export async function getUserConfig(): Promise<UserConfigBase | undefined> {
  try {
    const api = await Api()
    const res = await api.users.getUserConfig()
    return res
  } catch (e: any) {
    logErr('getUserConfig', { err: e.message })
    if (e.message == 'Unauthorized') {
      await signOut(auth) // not triggered by public, ok to signout
    }
  }
}

export async function getUserConfigBotName(): Promise<string | undefined> {
  try {
    const api = await Api()
    const res = await api.users.getUserConfigBotName()
    return res
  } catch (e: any) {
    logErr('getUserConfigBotName', { err: e.message })
  }
}

export async function updateBotName(botName: string): Promise<string | Error> {
  try {
    logInfo('Set Bot Name', { botName })
    const api = await Api()
    return await api.users.updateBotName(botName)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('updateBotName', { err })
    return err
  }
}

export async function updateEmailSettings(config: UserConfigBase): Promise<undefined | Error> {
  try {
    logInfo('Update Email Settings', { config })
    const api = await Api()
    await api.users.updateEmailSettings({
      email_transcript_done: config.email_transcript_done,
      email_summary_done: config.email_summary_done
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Update Email Settings', { err, config })
    return err
  }
}

export async function setZoomAuthCred(code?: string): Promise<string | undefined> {
  if (!code)
    return
  try {
    const api = await Api()
    const res = await api.users.setZoomAuthCred(code)
    return res
  } catch (e: any) {
    logErr('setZoomAuthCred', { err: e.message })
  }
}

export async function getUsage(): Promise<UserUsage | undefined> {
  if (!auth.currentUser)
    return
  try {
    const api = await Api()
    const res = await api.users.getUserUsage()
    return res
  } catch (e: any) {
    logErr('getUsage', { err: e.message })
  }
}

export async function deauthorizeZoom(): Promise<UserUsage | undefined> {
  try {
    const api = await Api()
    const res = await api.users.deauthorizeZoom()
    return res
  } catch (e: any) {
    logErr('deauthorizeZoom', { err: e.message })
  }
}

export async function saveLocation(): Promise<User | undefined> {
  try {
    const location = await ipInfo()
    logInfo('Save Location', { location })
    if (!location)
      return
    const api = await Api()
    const res = await api.users.saveLocation(location)
    return res
  } catch (e: any) {
    logErr('saveLocation', { err: e.message })
  }
}

export async function saveAffiliateCid(cid: string, affiliateId: string | null): Promise<User | undefined> {
  try {
    logInfo('saveAffiliateCid', { cid, affiliateId })
    const api = await Api()
    const res = await api.users.saveAffiliateCid(cid, affiliateId ?? "")
    return res
  } catch (e: any) {
    logErr('saveAffiliateCid', { err: e.message })
  }
}

export async function updateTimezone(): Promise<User | undefined> {
  try {
    // timezone is loaded from the local machine instead of ip
    // later being used for timestamps for CRM notes
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    if (!timeZone)
      return
    const api = await Api()
    const res = await api.users.updateTimezone(timeZone)
    return res
  } catch (e: any) {
    logErr('updateTimezone', { err: e.message })
  }
}

export async function ipInfo() {
  try {
    const response = await fetch('https://ipapi.co/json/')
    const res = await response.json()
    // timezone is loaded from the local machine instead of ip
    // later being used for timestamps for CRM notes
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    let timezoneStr = res['timezone']
    if (timeZone) {
      timezoneStr = timeZone
    }
    const location: LocationBase = {
      country_code: res['country_code'],
      country_name: res['country_name'],
      region: res['region'],
      timezone: timezoneStr,
      city: res['city'],
      postal: res['postal'],
      utc_offset: res['utc_offset']

    }
    return location
  } catch (e: any) {
    logErr('ipInfo', { err: e.message })
  }
}

export async function updateNameInDB(name: string): Promise<void> {
  try {
    const api = await Api()
    return await api.users.updateName(name)
  } catch (e: any) {
    logErr('updateNameInDB', { err: e.message })
  }
}

export async function addAutoShare(targetUser?: OrgUser | null, access?: Access): Promise<undefined | Error> {
  logInfo('Auto Share, Add User', { targetUser, access })
  if (!targetUser || !access)
    return new Error('missing input data')

  try {
    const api = await Api()
    const target: Permission = { ...targetUser, access }
    await api.users.addAutoshare(target)
    return
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('addAutoShare', { err })
    return err
  }
}

export async function updateAutoshareAll(target: Permission[]): Promise<undefined | Error> {
  try {
    const api = await Api()
    await api.users.updateAutoshareAll(target)
    return
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('updateAutoshareAll', { err })
    return err
  }
}

export async function updateSignupInfo(signupInfo: SignupInfo) {
  try {
    const api = await Api()
    return await api.users.updateSignupInfo(signupInfo)
  } catch (e: any) {
    const err = getErrorMessage(e)
    logErr('updateSignupInfo', { err })
    return err
  }
}