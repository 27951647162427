import React, { useEffect, useState } from 'react'
import { Typography, Snackbar, Alert, Tooltip } from '@mui/material'
import 'firebase/auth'
import { LoadingButton } from '@mui/lab'
import { importSalesforceContacts } from 'service/integrations/salesforceApi'
import { SalesforceApp } from 'client'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  app: SalesforceApp
}
export default function SalesforceImportContacts({ app }: Props) {
  const queryClient = useQueryClient()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [numImported, setNumImported] = useState<number | undefined>()

  async function onImportContacts() {
    setErr(undefined)
    setBusy(true)
    const res = await importSalesforceContacts()
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
      setNumImported(res)
      await queryClient.invalidateQueries({ queryKey: ['getUserClients'] })
    }
    setBusy(false)
  }

  return (
    <>
      <Tooltip title={<span>Imports {app} contacts<br />into FinMate AI Clients</span>}>
        <LoadingButton
          onClick={onImportContacts}
          loading={busy}
          variant='outlined'
        >
          Import {app} Contacts
        </LoadingButton>
      </Tooltip>

      {err && <Typography sx={{ color: 'tomato' }}>
        {err}
      </Typography>
      }

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          {numImported} {app} Contacts Synced Successfully
        </Alert>
      </Snackbar>
    </>
  )
}