import React from 'react'
import { SxProps, Typography, ListItemButton } from '@mui/material'
import { ClientBase } from '../../client'
import Grid from '@mui/material/Unstable_Grid2'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../routes'

interface Props {
  data?: ClientBase
}

export default function ClientListItemButton({ data }: Props) {
  const navigate = useNavigate()
  const clientId = data?.id

  const date = data?.last_event_datetime
    ? new Date(data?.last_event_datetime).toLocaleString()
    : ''

  return (
    <ListItemButton sx={{ width: '100%' }} onClick={() => navigate(`${Path.ClientDetail}/${clientId}`)} aria-label={data?.first_name + " " + data?.last_name} >
      <Grid sx={sxLine} container spacing={3}>
        {/* <Grid xs={2}>
          TODO photo url
        </Grid> */}
        <Grid xs={4}>
          <Typography>{`${data?.first_name ?? ''} ${data?.last_name ?? ''}`}</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography>{data?.email}</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography>{date}</Typography>
        </Grid>
      </Grid>
    </ListItemButton >
  )
}

const sxLine = {
  padding: '1vh',
  width: '100%',
}
