import React, { useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  Autocomplete,
  DialogActions,
  Divider,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from '@mui/material'
import { ClientBase, EventBase } from '../../client'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Path } from '../../routes'
import { useNavigate } from 'react-router-dom'
import { logInfo } from '../../log'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { getUserClients, setClientEvent } from 'service/clientApi'
import { gSx } from 'styles/Theme'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import ImportContactsListButtons from 'components/integrations/ImportContactsListButtons'

interface Props {
  event?: EventBase

  setIsAddStep: (isAdd: boolean) => void
  onClose: () => void
  onSuccessSnack: () => void
}

export default function ClientForEventExisting({ event, setIsAddStep, onClose, onSuccessSnack }: Props) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const eventId = event?.id ?? ''
  const [selected, setSelected] = useState<ClientBase | undefined>(event?.client)
  const [busy, setBusy] = useState(false)

  const { data: clients, isLoading } = useQuery({
    queryKey: ['getUserClients'], queryFn: getUserClients,
  })

  async function onSave() {
    setBusy(true)
    logInfo('Event, client details, set client', { eventId, clientId: selected?.id })
    await setClientEvent(eventId, selected?.id)
    await queryClient.invalidateQueries({ queryKey: ['getEvent', eventId] })
    await queryClient.invalidateQueries({ queryKey: ['getSpeakersAndAssignees', eventId] })
    onSuccessSnack()
    setBusy(false)
    onClose()
    setBusy(false)
  }

  function optionLabel(option: ClientBase): string {
    if (option.first_name)
      return `${option.first_name ?? ''} ${option.last_name ?? ''}, ${option.email}`
    return `${option.email}`
  }

  return (
    <>
      <Box sx={gSx.RowBetween}>
        <DialogTitle>  Set the Client for this Meeting</DialogTitle>
        <IconButton
          sx={{ alignSelf: 'flex-start' }}
          aria-label='close'
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>

        <Box sx={{ padding: '1vh' }} />

        {
          (isLoading || clients instanceof Error)
            ? <Skeleton variant="rectangular" width={'100%'} height={200} />
            : (
              <>
                <Autocomplete
                  disablePortal
                  options={clients ?? []}
                  getOptionLabel={optionLabel}
                  renderInput={(params) => <TextField {...params} label='Existing Clients' />}
                  value={selected}
                  onChange={(event: any, newValue) => {
                    // event.preventDefault()
                    setSelected(newValue ?? undefined)
                  }}
                  disabled={!clients || isLoading}
                />

                <Button
                  onClick={() => navigate(`${Path.ClientDetail}/${selected?.id}`)}
                  disabled={isLoading}
                >
                  {selected
                    ? `Edit client`
                    : <Box sx={{ padding: 1.2 }} />
                  }
                </Button>
              </>
            )
        }

      </DialogContent>

      <DialogActions sx={{ paddingY: 0, marginY: 0 }}>
        <LoadingButton
          variant='contained'
          onClick={onSave}
          disabled={selected === null}
          sx={{ width: 100 }}
          loading={busy}
        >
          Save
        </LoadingButton>
      </DialogActions>

      <DialogContent >

        <Box sx={gSx.Row}>
          <Divider sx={{ width: '46%' }} />
          <Typography sx={{ width: '8%', textAlign: 'center' }}>OR</Typography>
          <Divider sx={{ width: '46%' }} />
        </Box>

        <Box sx={{ padding: '1vh' }} />

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Button
            variant='outlined'
            onClick={() => setIsAddStep(true)}
            startIcon={<PersonAddIcon />}
          >
            Add a new Client
          </Button>

          <ImportContactsListButtons />
        </Box>
      </DialogContent>
    </>
  )
}
