import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Divider, IconButton, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { EventBase, SalesforceApp } from 'client'
import EditIcon from '@mui/icons-material/Edit'
import { gColor, gSx } from 'styles/Theme'
import ClientEventDialog from 'components/client/ClientCardForEventDialog'

interface Props {
  event?: EventBase
  app: SalesforceApp
}

export default function SalesforceCrmClientCard({ event, app }: Props) {
  const hasClientEmail = event?.client?.email ?? false
  const [showAddClientDialog, setShowAddClientDialog] = useState(false)
  return (
    <Card>
      <Grid container >
        <Grid xs={2}>
          <Typography variant='h5'>Client</Typography>
        </Grid>
        <Grid xs={10}>
          <Typography color={gColor.green500}>
            {event?.client?.first_name ?? ''} {event?.client?.last_name ?? ''}
          </Typography>
          <Typography color={gColor.green500}>{event?.client?.email}</Typography>

          {!hasClientEmail &&
            <>
              <Box sx={gSx.Row}>
                <Typography sx={{ color: 'red', fontWeight: 'bold', cursor: 'pointer' }}
                  onClick={() => setShowAddClientDialog(true)}
                >
                  Add a client
                </Typography>
                <IconButton
                  aria-label='edit'
                  onClick={() => setShowAddClientDialog(true)}
                >
                  <EditIcon fontSize='small' />
                </IconButton>
              </Box>

              <Typography >
                The Email should match your existing CRM contact, or Finmate will automatically create a new CRM contact for you.
              </Typography>
            </>
          }
          <ClientEventDialog
            event={event}
            open={showAddClientDialog}
            onClose={() => setShowAddClientDialog(false)}
          />
        </Grid>
      </Grid>
    </Card>
  )
}
