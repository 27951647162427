import { ActionItem, ClientBase, SalesforceApp, SalesforceBase, SalesforceWho } from "client"
import { logErr, logInfo } from "log"
import { Api, config } from "../api"
import { getErrorMessage } from "utils"
import queryString from 'query-string'
import { Media } from "service/media"

// pop up window
export async function salesforceOAuth(app: SalesforceApp) {

  localStorage.setItem('SalesforceApp', app)

  const windowName = 'PopupWindow'
  const windowFeatures = 'width=500,height=700'

  const queryParams = config.env == 'local' ? {
    // localhost http
    client_id: config.salesforceClientId,
    redirect_uri: `${config.salesforceRedirect}/localhost`,
    response_type: "token",
    prompt: "login"

    // ngrok https. see readme.
    // client_id: config.salesforceClientId,
    // redirect_uri: `https://c054-24-5-149-147.ngrok-free.app/auth/salesforce/callback`,
    // response_type: "code",
    // prompt: "login"
  } : {
    client_id: config.salesforceClientId,
    redirect_uri: config.salesforceRedirect,
    response_type: "code",
    prompt: "login",
    scope: "refresh_token offline_access web api id profile email openid"
  }

  try {
    // https://practifi.cloudforce.com/ does not provide 0auth2 credentials
    const baseUrl = `https://login.salesforce.com/services/oauth2/authorize`
    const url = `${baseUrl}?${queryString.stringify(queryParams)}`

    logInfo('Salesforce OAuth Sign In', { url, queryParams })
    window.open(url, windowName, windowFeatures)
  } catch (e) {
    logErr('Salesforce OAuth Sign In')
  }
}

export async function exchangeAuthCodeForTokens(code?: string) {
  if (!code)
    return new Error('no auth code')

  let salesforceApp = SalesforceApp.SALESFORCE
  const storageItem = localStorage.getItem('SalesforceApp')
  if (storageItem != null)
    salesforceApp = storageItem as SalesforceApp

  try {
    const api = await Api()
    await api.salesforce.exchangeAuthCodeForTokens(code, salesforceApp)
    return salesforceApp
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('exchangeAuthCodeForTokens', { err })
    return err
  }
}

export async function saveSalesforceAuthLocalhost(data: SalesforceBase) {
  try {
    data.app = SalesforceApp.SALESFORCE
    const storageItem = localStorage.getItem('SalesforceApp')
    if (storageItem != null)
      data.app = storageItem as SalesforceApp

    const api = await Api()
    await api.salesforce.saveSalesforceAuthLocalhost(data)
    return true
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('saveSalesforceAuthLocalhost', { err })
    return err
  }
}

export async function getSalesforceSettings(): Promise<SalesforceBase | undefined> {
  try {
    const api = await Api()
    return await api.salesforce.getSalesforceSettings()
  } catch (e) {
    logErr('getSalesforceSettings', { e })
  }
}

export async function importSalesforceContacts(): Promise<number | Error> {
  try {
    logInfo('salesforce importContacts')
    const api = await Api()
    return await api.salesforce.importContacts()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('salesforce importContacts', { err })
    return err
  }
}

export async function addSalesforceClientNote(client: ClientBase, eventId: string, app: SalesforceApp, tasks: ActionItem[], notes?: string): Promise<Error | undefined> {
  try {
    logInfo('salesforce Add Client Note', { client, eventId, app })
    const api = await Api()
    await api.salesforce.addSalesforceClientNote({
      event_id: eventId,
      app,
      client,
      summary_plain_text: notes,
      tasks
    })

  } catch (e) {
    const err = getErrorMessage(e)
    logErr('salesforce addClientNote', { err })
    return err
  }
}

export async function disconnect(): Promise<undefined | Error> {
  try {
    logInfo('salesforce Disconnect')
    const api = await Api()
    await api.salesforce.disconnectSalesforce()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('salesforce disconnect', { err })
    return err
  }
}

export function isSalesforceApp(app: any): app is SalesforceApp {
  return Object.values(SalesforceApp).includes(app)
}

export async function updateWho(who: SalesforceWho): Promise<undefined | Error> {
  try {
    logInfo('salesforce updateWho')
    const api = await Api()
    await api.salesforce.updateDefaultCreateWho(who)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('salesforce updateWho', { err })
    return err
  }
}

export function getSalesforceIcon(app?: SalesforceApp) {
  if (app == SalesforceApp.PRACTIFI)
    return Media.practifiIcon
  if (app == SalesforceApp.XLR8)
    return Media.xlr8Icon
  return Media.salesforceIcon
}