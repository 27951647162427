import { logErr } from "log"
import { Api } from "./api"
import { ClientBase, ListEvents } from "client"
import { getErrorMessage, isEmailValid } from "utils"

export async function getClientDetail(client_id?: string) {
  if (!client_id)
    return
  try {
    const api = await Api()
    return await api.clients.getClientDetail(client_id)
  } catch (e) {
    logErr('getClientDetail', { e })
  }
}

export async function getUserClients() {
  try {
    const api = await Api()
    return await api.clients.getUserClients()
  } catch (e) {
    logErr('getUserClients', { e })
  }
}

export async function setClientEvent(eventId: string, clientId?: string) {
  if (!clientId)
    return
  try {
    const api = await Api()
    return await api.events.setClientEvent(eventId, clientId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('setClientEvent', { err })
    return err
  }
}

export async function upsertClient(client: ClientBase, eventId?: string) {

  if (client.email && !isEmailValid(client.email ?? ''))
    return new Error('Email is invalid') // updating notes doesnt pass email
  try {
    const api = await Api()
    return await api.clients.upsertClient(eventId ?? '', client)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('upsertClient', { err })
    return err
  }
}

export async function deleteClient(clientId?: string) {
  if (!clientId)
    return new Error('missing client Id')
  try {
    const api = await Api()
    return await api.clients.deleteClient(clientId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('deleteClient', { err })
    return err
  }
}
