import React, { ReactNode, useEffect, useState } from 'react'
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  // createRow,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table'
import { checkAddPayingSeat, checkSwapPayingSeat, getIsLiteMaxedOut, getOrgUser, isAtLeastOneActiveAdmin, updateOrgUser } from '../../service/orgsApi'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Alert, Box, Card, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { FinmateSubscription, OrgBase, OrgUser, OrgUserRole, ProductName } from '../../client'
import { logInfo } from 'log'
import { addToSubscription, createCheckoutSession, getCustomer, swapSubscription } from 'service/payApi'
import SwapPayingSeatInfo from './SwapPayingSeatInfo'
import AddPayingSeatInfo from './AddPayingSeatInfo'
import Grid from '@mui/material/Unstable_Grid2'
import ProfilePhoto from './ProfilePhoto'
import CheckIcon from '@mui/icons-material/Check'
import { auth } from 'service/api'
import { getUser } from 'service'

interface Props {
  org?: OrgBase
  sub?: FinmateSubscription
  isLoadingParent: boolean
}

interface CellProps {
  renderedCellValue: ReactNode
  row: MRT_Row<OrgUser>
}

export default function OrgUsers({ org, sub, isLoadingParent }: Props) {
  const queryClient = useQueryClient()

  const { data: user } = useQuery({
    queryKey: ['getUser'], queryFn: getUser, refetchOnWindowFocus: false
  })
  const clientReferenceId = user?.affiliate_cid

  const isSubscribed = !!sub?.subscription
  const OrgUserRoleOptions = [OrgUserRole.ADMIN, OrgUserRole.MEMBER, OrgUserRole.INACTIVE]

  const fbUser = auth.currentUser
  const fbOrgUser = getOrgUser(org, fbUser?.uid)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [busy, setBusy] = useState(false)
  const [editedUser, setEditedUser] = useState<OrgUser>({})
  const [isAddPaySeat, setAddSeat] = useState(false)
  const [isSwapPaySeat, setSwapPaySeat] = useState(false)
  const [isLiteMaxedOut, setIsLiteMaxedOut] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({})

  const { data: customer } = useQuery({ queryKey: ['getCustomer'], queryFn: getCustomer })

  const isLoading = isLoadingParent

  useEffect(() => {
    setErr(undefined)
    const uid = editedUser.uid
    const originalUser = getOrgUser(org, uid)
    const swap = checkSwapPayingSeat(org, sub, originalUser, editedUser)
    const add = checkAddPayingSeat(org, sub, originalUser, editedUser)
    const liteMaxed = getIsLiteMaxedOut(org, sub, originalUser, editedUser)
    setSwapPaySeat(swap)
    setAddSeat(add)
    setIsLiteMaxedOut(liteMaxed)
    if (liteMaxed)
      setErr('No more Lite plan seats available')
    logInfo('Org Users Edit, change', { editedUser, isSubscribed, isAddPaySeat: add, swap })
  }, [editedUser])

  useEffect(() => {
    logInfo('validationErrors', validationErrors)
  }, [validationErrors])

  const columns: MRT_ColumnDef<OrgUser>[] = [
    {
      accessorKey: 'profileUrl',
      header: '',
      enableEditing: false,
      size: 50,
      Cell: CellImage,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      enableEditing: false,
      size: 150,
      Cell: CellText,
    },
    {
      accessorKey: 'email',
      header: 'Email',
      enableEditing: false,
      size: 150,
      Cell: CellText,
    },
    {
      accessorKey: 'org_user_role',
      header: 'Role',
      editVariant: 'select',
      editSelectOptions: OrgUserRoleOptions,
      size: 80,
      muiEditTextFieldProps: ({ row }) => ({
        select: true,
        type: 'org_user_role',
        required: true,
        onChange: (event) => {
          const role = event.target.value as OrgUserRole
          const u: OrgUser = { ...row._valuesCache, org_user_role: role, uid: row.id }
          setEditedUser(u)
        },
        error: !!validationErrors?.role,
        helperText: validationErrors?.role,
      }),
      Cell: CellText,
    },
    {
      accessorKey: 'org_user_plan',
      header: 'Plan',
      editVariant: 'select',
      editSelectOptions: ({ row }) => getPlanOptions(row.original.org_user_plan),
      size: 80,
      muiEditTextFieldProps: ({ row }) => ({
        select: true,
        type: 'org_user_plan',
        required: true,
        onChange: (event) => {
          const plan = event.target.value as ProductName
          const u: OrgUser = { ...row._valuesCache, org_user_plan: plan, uid: row.id }
          setEditedUser(u)
        },
        error: !!validationErrors?.plan,
        helperText: validationErrors?.plan,
      }),
      Cell: CellText,
    },
  ]

  const handleSaveUser: MRT_TableOptions<OrgUser>['onEditingRowSave'] = async ({
    row, values, table,
  }) => {
    setErr(undefined)
    setBusy(true)

    const targetOrgUser: OrgUser = { ...values, uid: row.id }
    const originalOrgUser = row.original
    logInfo('Org Users Edit, Pressed Save', { originalOrgUser, targetOrgUser })

    if (isLiteMaxedOut) {
      setErr('No more Lite plan seats available')
      setBusy(false)
      return
    }

    const validErr = validate(targetOrgUser, org, customer?.email)
    if (Object.values(validErr).some((error) => error)) {
      setValidationErrors(validErr)
      setBusy(false)
      return
    }

    setValidationErrors({})
    const standard = editedUser.org_user_plan == ProductName.STANDARD ? 1 : 0
    const starter = editedUser.org_user_plan == ProductName.STARTER ? 1 : 0

    let done = false
    if (!isSubscribed && isAddPaySeat) {
      const res = await createCheckoutSession(standard, starter, 0, targetOrgUser, clientReferenceId)
      if (res instanceof Error) {
        setErr(String(res))
        setBusy(false)
        return
      } else if (res?.session_url)
        window.location.href = res?.session_url
    } else if (isSubscribed && isSwapPaySeat) {    // switching from starter <-> standard
      const res = await swapSubscription(editedUser)
      if (res)
        done = true
    } else if (isSubscribed && isAddPaySeat) {
      const res = await addToSubscription(standard, starter, 0, editedUser)
      if (res)
        done = true
    } else {
      const res = await updateOrgUser(targetOrgUser)
      if (res instanceof Error) {
        setErr(res.message)
      } else {
        done = true
      }
    }

    if (done) {
      setSnackSuccess(true)
      await queryClient.invalidateQueries({ queryKey: ['getSubscription'] })
      await queryClient.invalidateQueries({ queryKey: ['getOrg'] })
      await queryClient.invalidateQueries({ queryKey: ['getUser'] })
      table.setEditingRow(null) // exit edit
    }
    setBusy(false)
  }

  const table = useMaterialReactTable({
    muiEditRowDialogProps(props) {
      return { ...props, maxWidth: 'md', open: true }
    },
    columns,
    data: org?.org_user_list ?? [],
    createDisplayMode: 'modal',
    editDisplayMode: 'modal',
    enableEditing: true,
    getRowId: (row) => row.uid ?? '',
    muiTableContainerProps: { sx: { minHeight: '500px' } },
    onEditingRowCancel: () => {
      setValidationErrors({})
    },
    onEditingRowSave: handleSaveUser,

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => {
      const visibleRows = internalEditComponents.slice(3) // remove profile, name and email in dialog

      return (
        <>
          <DialogTitle variant="h3">Edit User</DialogTitle>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
          >

            <Grid container spacing={2}>
              <Grid xs={12} md={6}>
                <Definitions />

                {isSwapPaySeat &&
                  <SwapPayingSeatInfo
                    org={org}
                    sub={sub}
                    target={editedUser}
                    customer={customer}
                  />
                }
                {isAddPaySeat &&
                  <AddPayingSeatInfo
                    sub={sub}
                    target={editedUser}
                    customer={customer}
                  />
                }
              </Grid>
              <Grid xs={12} md={6}>
                <Typography variant='h5'>{row.original.name}</Typography>
                <Typography variant='h5'>{row.original.email}</Typography>
                <Box sx={{ padding: 2 }} />
                {
                  visibleRows.map((comp, idx) =>
                    <Box key={idx}>
                      {comp}

                      <Box sx={{ padding: 2 }} />
                    </Box>)
                }
              </Grid>
            </Grid>

          </DialogContent>
          {err && <DialogActions>
            <Typography color={'tomato'} >{err}</Typography>
          </DialogActions>
          }
          <DialogActions>
            <MRT_EditActionButtons variant="text" table={table} row={row} />
          </DialogActions>
        </>
      )
    },
    renderRowActions: ({ row, table }) => {
      if (fbOrgUser?.org_user_role != OrgUserRole.ADMIN)
        return null
      return (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="Edit">
            <IconButton onClick={() => {
              table.setEditingRow(row)
              setEditedUser(row.original)
            }}>
              <EditIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Box>
      )
    },
    state: {
      isLoading: isLoading,
      isSaving: busy,
    },
    initialState: { columnPinning: { right: ['mrt-row-actions'] } },
  })

  return (
    <>
      <MaterialReactTable table={table} />

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          User has been successfully updated!
        </Alert>
      </Snackbar>
    </>
  )
}

function validate(targetUser: OrgUser, org?: OrgBase, customer_email?: string) {
  const targetInactive = targetUser.org_user_role == OrgUserRole.INACTIVE
  const targetPaidPlan = targetUser.org_user_plan == ProductName.STANDARD || targetUser.org_user_plan == ProductName.STARTER
  const res: Record<string, string | undefined> = {}

  if (targetInactive && targetUser.email == customer_email)
    res.role = 'User is Billing Admin. User cannot be deactivated'

  if (!isAtLeastOneActiveAdmin(targetUser, org))
    res.role = 'Require at least 1 active administrator'

  if (targetInactive && targetPaidPlan)
    res.plan = 'Inactive User cannot be on a Standard or Starter Plan'

  return res
}

function Definitions() {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
      <Typography>Role</Typography>
      <Typography variant="caption"><b>Admin: </b> Handles role assignments, plan selection, and billing contacts.</Typography>
      <Typography variant="caption"><b>Member: </b> Standard feature access.</Typography>
      <Typography variant="caption"><b>Inactive: </b> Access disabled, plan marked as Expired.</Typography>

      <Box sx={{ padding: 1 }} />
      <Typography>Plan</Typography>
      <Typography variant="caption"><b>Standard: </b> Includes meeting note-taking; 40 hours/month.</Typography>
      <Typography variant="caption"><b>Starter: </b> Includes meeting note-taking; 20 hours/month.</Typography>
      <Typography variant="caption"><b>Lite: </b> Access to shared events. Viewer and Editor Permissions. </Typography>
      <Typography variant="caption"><b>Expired: </b> Features disabled. Viewer only Permissions.</Typography>
    </Card>
  )
}

function getPlanOptions(initialPlan?: ProductName) {
  initialPlan = initialPlan ? initialPlan : ProductName.FREE_TRIAL
  if (initialPlan == ProductName.FREE_TRIAL)
    return [ProductName.STANDARD, ProductName.STARTER, ProductName.LITE, ProductName.FREE_TRIAL]
  return [ProductName.STANDARD, ProductName.STARTER, ProductName.LITE, ProductName.EXPIRED]
}

function CellText({ renderedCellValue, row }: CellProps) {
  const isInActive = row.original.org_user_role == OrgUserRole.INACTIVE
  return (
    <Typography sx={isInActive ? {
      textDecoration: 'line-through',
      color: 'gray',
    } : {}}>
      {renderedCellValue}
    </Typography>
  )
}

function CellImage({ renderedCellValue, row }: CellProps) {
  return (
    <ProfilePhoto
      photoUrl={row.original.photo_url}
      width={35}
    />
  )
}
