import React from 'react'
import { Typography, Box, Card, SxProps, Link, Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { GetCalendarUser, ListCalendarMeetings } from '../../service/calendarApi'
import { CalendarEvent } from '../../client'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Path } from '../../routes'
import { useNavigate } from 'react-router-dom'
import SignInCalendarButton from './SignInCalendarButton'
import { isCalendarConnected } from 'utils'
import { CalendarSkeletons } from 'components/Skeletons'
import CalendarEventSimple from './CalendarEventSimple'
import { getRedtailSettings } from 'service/integrations/redtailApi'

export default function ListCalendarEvents() {
  const navigate = useNavigate()

  const { data: calUser, isLoading: isLoadingCal } = useQuery({
    queryKey: ['GetCalendarUser'], queryFn: GetCalendarUser,
  })
  const isCalConnected = isCalendarConnected(calUser)
  const { data, isLoading: isLoadingCalMeetings } = useQuery({
    queryKey: ['ListCalendarMeetings'], queryFn: ListCalendarMeetings
  })
  const { data: redtailSettings, isLoading: isLoadingRedtail } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings,
  })
  const isRedtailSignedIn = redtailSettings?.user_id ?? false
  const isLoading = isLoadingCalMeetings || isLoadingCal || isLoadingRedtail
  const isSignedIn = isCalConnected || isRedtailSignedIn

  const short = data && data.slice(0, 3)

  if (isLoading)
    return (
      <CalendarSkeletons />
    )

  if (!isLoading && !isSignedIn)
    return (
      <Card>
        <Typography variant='h3'>Next Calendar Events</Typography>
        <Box sx={{ padding: '1vh' }} />
        <Typography>
          FinMate AI can automatically join your customer meetings, by connecting your calendar.
        </Typography>
        <Box sx={{ padding: '1vh' }} />
        <SignInCalendarButton />
      </Card>
    )

  return (
    <Card>
      <Typography variant='h3'>Next Calendar Events</Typography>
      <Box sx={{ padding: '1vh' }} />

      {
        isLoading || !short ? (
          <>
            <Skeleton variant="rectangular" width={'95%'} height={100} />
            <Skeleton variant="rectangular" width={'95%'} height={100} />
            <Skeleton variant="rectangular" width={'95%'} height={100} />
          </>
        ) : (
          short.length === 0 ? (
            <Typography sx={{ mt: 5 }}>No upcoming events</Typography>
          ) : (
            short?.map(event => {
              const date = new Date(event.start_time ?? '')
              const readable = date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' })
              return (
                <Box key={event.id}>
                  {
                    ShowDateIfEarliest(date, short) &&
                    <Box sx={sxDate}>
                      <CalendarTodayIcon />
                      <Box sx={{ paddingX: '1vh' }} />
                      <Typography>{readable}</Typography>
                    </Box>
                  }
                  <CalendarEventSimple event={event} isSmall />
                </Box>
              )
            })
          )
        )
      }

      <Link sx={{ cursor: 'pointer' }} onClick={() => navigate(Path.Calendar)}>See More</Link>
    </Card>
  )
}

const sxDate: SxProps = {
  paddingX: '2vh',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}

function ShowDateIfEarliest(date: Date, all: CalendarEvent[]) {
  const meetingsOftheDay = all.filter(m => (new Date(m.start_time ?? '')).toLocaleDateString() === date.toLocaleDateString())
  for (const m of meetingsOftheDay) {
    if (new Date(m.start_time ?? '') < date)
      return false
  }
  return true
}
