import React, { useEffect } from 'react'
import { Box, SxProps, LinearProgress } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { Media } from 'service/media'
import { logInfo } from 'log'
import { getUser, saveLocation } from 'service'
import { requestNotificationPermission } from 'service/msgApi'
import mixpanel from 'mixpanel-browser'
import { auth, config } from 'service/api'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { GetCalendarUser } from 'service/calendarApi'
import { getOrgInvitesAsRecipient } from 'service/orgsApi'
// import GoogleConsentButton from './GoogleConsentButton'

export default function LoadingScreen() {
  const navigate = useNavigate()
  const fbUser = auth.currentUser
  const location = useLocation()
  const currentParams = new URLSearchParams(location.search) // may contain affiliate
  const homePathWithParams = `${Path.Home}?${currentParams.toString()}`

  useEffect(() => {
    logInfo(`Loading Screen`)
    if (fbUser == null)
      navigate(Path.SignIn)
  }, [])

  useQuery({ // preload cal
    queryKey: ['GetCalendarUser'],
    queryFn: GetCalendarUser,
  })

  useEffect(() => {
    run()
  }, [])

  async function run() {
    localStorage.clear()
    mixpanel.identify(fbUser?.uid)
    mixpanel.people.set({
      name: fbUser?.displayName,
      "$email": fbUser?.email
    })

    const user = await getUser()
    await saveLocation()

    if (user?.blocked_pending_demo) {
      navigate(Path.BlockedPendingDemo)
      return
    }

    const invites = await getOrgInvitesAsRecipient()
    if (invites && invites.length > 0) {
      navigate(Path.ReceivedInvite)
      return
    }

    requestNotificationPermission() // do not allow await. for mobile web app users.

    navigate(homePathWithParams)
  }

  return (
    <Box sx={sxPage}>
      <Box component='img' sx={sxLogo} src={Media.logo100} />
      <Box sx={{ width: '30%', paddingTop: 5 }}>
        <LinearProgress />
      </Box>
    </Box >
  )
}

const sxPage: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  width: '100%',
}

const sxLogo: SxProps = {
  height: 30,
  width: 22,
  resizeMode: 'contain',
  alignContent: 'center',
  paddingLeft: '5px',
  paddingRight: '10px',
}