import React, { useEffect, useState } from 'react'
import { Alert, Autocomplete, Popover, Snackbar, TextField } from '@mui/material'
import { AssigneeInfo, CRM } from 'client'
import _ from 'lodash'
import AssigneePopoverRender from 'components/event/AssigneePopoverRender'
import { assigneeDisplay } from 'service/eventApi'

interface Props {
  showPopover: HTMLButtonElement | null
  closePopover: () => void
  crm: CRM
  allAssignees: AssigneeInfo[]
  onChangeAssignee: (assignee: AssigneeInfo) => void
}

export default function CrmAssigneePopover(props: Props) {
  const { showPopover, closePopover, onChangeAssignee, crm, allAssignees } = props
  const featuredAssignees = allAssignees.filter(a => a.is_featured)

  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)
  const [openCrmUsers, setOpenCrmUsers] = useState(false)
  const [updatedAllAssignees, setUpdatedAllAssignees] = useState(featuredAssignees)

  useEffect(() => {
    setUpdatedAllAssignees(featuredAssignees)
  }, [allAssignees])

  useEffect(() => {
    showPopover
      ? setTimeout(() => { setOpenCrmUsers(true) }, 170)
      : setOpenCrmUsers(false)
  }, [showPopover])

  const onInputChange = (event: any, value: string | null) => {
    if (!value || value.length == 0) {
      setUpdatedAllAssignees(featuredAssignees)
      return
    }
    const query = value.toLowerCase()
    const matching = allAssignees.filter(item =>
      item.name?.toLowerCase().includes(query)
    )
    setUpdatedAllAssignees(matching)
  }

  const onChange = (event: any, value: AssigneeInfo | string | null) => {
    let updatedAssignee: AssigneeInfo = {}
    if (value != null && typeof value == 'object') {
      updatedAssignee = value
    }
    else if (typeof value == 'string') {
      updatedAssignee.name = value
    }
    onChangeAssignee(updatedAssignee)
    closePopover()
    setUpdatedAllAssignees(featuredAssignees)
  }

  return (
    <>
      <Popover
        open={Boolean(showPopover)}
        anchorEl={showPopover}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Autocomplete
          openOnFocus
          open={openCrmUsers}
          sx={{ width: 300, margin: 2 }}
          id="speaker"
          freeSolo
          options={updatedAllAssignees}
          getOptionLabel={(option) => assigneeDisplay(option)}
          renderOption={(props, option) =>
            <AssigneePopoverRender {...props} option={option} />
          }
          renderInput={(params) =>
            <TextField autoFocus {...params} label={`${crm} User (⏎ to save)`} />
          }
          onChange={onChange}
          onInputChange={onInputChange}
          disableClearable
        />
      </Popover>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          {`${crm} Assignee Updated`}
        </Alert>

      </Snackbar>
    </>
  )
}
