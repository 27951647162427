import React from 'react'
import { Avatar, Chip, SxProps } from '@mui/material'
import { AssigneeInfo } from '../../client'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { getInitial } from 'utils'
import EventTaskAssigneePopover from './EventTaskAssigneePopover'

interface Props {
  eventId?: string
  assignee: AssigneeInfo
  disabled?: boolean
  changeAssignee: (assignee: AssigneeInfo) => Promise<void>
}

export default function EventTaskAssigneeChip({ assignee, disabled, eventId, changeAssignee }: Props) {
  const isEmpty = !assignee.name || assignee.name == null
  const [showPopover, setShowPopover] = React.useState<HTMLButtonElement | null>(null)

  const handleAssigneeClickChip = (event: any) => {
    if (disabled)
      return
    setShowPopover(event.currentTarget)
  }

  return (
    <>
      <Chip
        label={assignee.name}
        avatar={isEmpty ? undefined : <Avatar>{getInitial(assignee.name)}</Avatar>}
        variant="outlined"
        sx={sxSpeakerChip}
        onClick={(event: any) => handleAssigneeClickChip(event)}
        icon={isEmpty ? <PersonAddAlt1Icon fontSize='small' sx={{ paddingLeft: 2 }} /> : undefined}
      />

      {
        eventId &&
        <EventTaskAssigneePopover
          eventId={eventId}
          showPopover={showPopover}
          closePopover={() => setShowPopover(null)}
          changeAssignee={changeAssignee}
        />
      }

    </>
  )
}

const sxSpeakerChip: SxProps = {
  fontSize: 12,
  fontWeight: 'bold',
  cursor: 'pointer',
  '&:hover': {
    color: "primary.main"
  }
}
