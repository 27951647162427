import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Box, Checkbox, Typography } from '@mui/material'
import { AssigneeInfo, CRM } from 'client'
import Grid from '@mui/material/Unstable_Grid2'
import { gSx } from 'styles/Theme'
import CrmAssigneeChip from './CrmAssigneeChip'
import { MasterTask } from './SelectTasks'

interface Props {
  originalAssignee?: string
  masterTasks: MasterTask[]
  setMasterTasks: Dispatch<SetStateAction<MasterTask[]>>
  disabled: boolean
  crm: CRM
  allAssignees: AssigneeInfo[]
  setAllAssignees: Dispatch<SetStateAction<AssigneeInfo[]>>
}

export default function SelectTasksByAssignee(props: Props) {
  const { originalAssignee, masterTasks, setMasterTasks, disabled, crm, allAssignees, setAllAssignees } = props
  const [selectedAssignee, setSelectedAssignee] = useState(false)

  const assigneeTasks = getTasksByOriginalAssignee(masterTasks, originalAssignee)
  const assigneeInfo = assigneeTasks[0].assignee_info

  useEffect(() => { // select all, auto
    const allSelected = assigneeTasks.every(task => task.isSelected)
    setSelectedAssignee(allSelected)
  }, [assigneeTasks])

  const toggleSelectByAssignee = (event?: any) => {
    event?.preventDefault()
    const updated: MasterTask[] = masterTasks.map(m =>
      originalAssignee === m.originalAssignee
        ? { ...m, isSelected: !selectedAssignee } : m
    )
    setMasterTasks(updated)
    setSelectedAssignee(!selectedAssignee)
  }

  const toggleSelectItem = (task: MasterTask) => {
    const updated: MasterTask[] = masterTasks.map(m =>
      task.id === m.id ? { ...m, isSelected: !task.isSelected } : m
    )
    setMasterTasks(updated)
  }

  const onChangeAssignee = async (updateAssignee: AssigneeInfo) => {
    const isFound = allAssignees.some(
      (a) => a.name === updateAssignee.name
    )
    const updatedAssignees = isFound
      ? allAssignees.map(a =>
        a.name === updateAssignee.name
          ? { ...updateAssignee, is_featured: true } : a)
      : [...allAssignees, { ...updateAssignee, is_featured: true }]
    setAllAssignees(updatedAssignees)

    const updatedTasks: MasterTask[] = masterTasks.map(m =>
      m.originalAssignee === originalAssignee
        ? { ...m, assignee_info: updateAssignee, assignee: updateAssignee.name } : m
    )
    setMasterTasks(updatedTasks)
  }

  function isRedtailUser() {
    return !!(assigneeTasks.length > 0 && assigneeTasks[0].assignee_info?.redtail_user_id)
  }
  function isWealthboxUser() {
    return !!(assigneeTasks.length > 0 && assigneeTasks[0].assignee_info?.wealthbox_user_id)
  }
  function isSalesforceUser() {
    return !!(assigneeTasks.length > 0 && assigneeTasks[0].assignee_info?.salesforce_user_id)
  }

  return (
    <Box sx={{ backgroundColor: '#f9f9f9', marginBottom: 1, borderRadius: 2, padding: 1 }}>

      <Box sx={gSx.Row}>
        <Checkbox
          checked={selectedAssignee}
          onChange={(event) => toggleSelectByAssignee(event)}
          disabled={disabled}
        />
        {
          crm == CRM.REDTAIL || crm == CRM.WEALTHBOX || crm == CRM.SALESFORCE ?
            <Box sx={gSx.Row}>
              <CrmAssigneeChip
                assigneeInfo={assigneeInfo}
                allAssignees={allAssignees}
                onChangeAssignee={onChangeAssignee}
                crm={crm}
                disabled={disabled}
              />
            </Box>
            : <Typography>{assigneeInfo?.name}</Typography>
        }

        <Box sx={{ paddingX: 0.5 }} />
        <Typography variant='caption' color={'gray'}>{`(Select all by Assignee)`}</Typography>
      </Box>

      {(
        (crm == CRM.REDTAIL && !isRedtailUser())
        || (crm == CRM.WEALTHBOX && !isWealthboxUser())
        || (crm == CRM.SALESFORCE && !isSalesforceUser())
      ) &&
        <Typography variant='caption'>Selected tasks will be assigned to you, since this person is not a {crm} User.</Typography>
      }

      <Box sx={{ padding: 0.5 }} />
      {assigneeTasks.map((t) => (
        <Grid container spacing={2} key={t.id}>
          <Grid xs={1} />
          <Grid xs={1}>
            <Checkbox
              sx={{ padding: 0 }}
              checked={t.isSelected}
              onChange={() => toggleSelectItem(t)}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={10}>
            <Typography>{t.description}</Typography>
          </Grid>
        </Grid>
      ))}

    </Box>
  )
}

function getTasksByOriginalAssignee(tasks: MasterTask[], originalAssignee?: string) {
  return tasks.filter(item => item.originalAssignee === originalAssignee)
}