import React, { useState } from 'react'
import { Grid, Typography, FormControl, Select, MenuItem, Skeleton, Tooltip } from '@mui/material'
import { getOrg, getOrgConfigs, getOrgUser, updateOrgConfigs } from 'service/orgsApi'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { OrgConfigs, OrgUserRole } from 'client'
import { auth } from 'service/api'

const options = [
  { value: 90, label: '3 months' },
  { value: 180, label: '6 months' },
  { value: 365, label: '1 year' },
  { value: 730, label: '2 years' },
]

const video_options = [
  { value: 30, label: '1 months' },
  { value: 90, label: '3 months' },
  { value: 180, label: '6 months' },
  { value: 365, label: '1 year' },
]

const OrgDataRetention = () => {

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg
  })
  const fbUser = auth.currentUser
  const orgUser = getOrgUser(org, fbUser?.uid)

  const queryClient = useQueryClient()
  const selectReadOnly = orgUser?.org_user_role != OrgUserRole.ADMIN

  // TODO: only allow admin to change
  const { data, isLoading } = useQuery({
    queryKey: ['getOrgConfigs'],
    queryFn: async () => await getOrgConfigs(),
  })

  const mutation = useMutation({
    mutationFn: async (newOrgConfigs: OrgConfigs) => {
      await updateOrgConfigs(newOrgConfigs)
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['getOrgConfigs'] })
    },
  })

  const handleRetentionChange = (updatedChange: any) => {
    console.log('handleRetentionChange', updatedChange.target.value)
    const newOrgConfigs = {
      data_retention_days_summary: updatedChange.target.value,
      data_retention_days_transcript: updatedChange.target.value,
    } as OrgConfigs
    mutation.mutate(newOrgConfigs)
  }
  const skeletonHeight = 50
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <Typography variant="subtitle1">Retain summary and transcript for:</Typography>
      </Grid>
      <Grid item xs={6}>
        {
          isLoading && <>
            <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
          </>
        }{
          !isLoading &&
          <Tooltip title={selectReadOnly ? "Adjustable only by Admin" : ""}>
            <FormControl variant="outlined" fullWidth>
              <Select
                labelId="retention-select-label"
                id="retention-select"
                value={data.data_retention_days_summary}
                onChange={handleRetentionChange}
                inputProps={{ readOnly: selectReadOnly }}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>

        }
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1">Retain video for:</Typography>
      </Grid>
      <Grid item xs={6}>
        {
          isLoading && <>
            <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
          </>
        }{
          !isLoading &&
          <Tooltip title="Enterprise plan only">
            <FormControl variant="outlined" fullWidth>
              <Select
                labelId="video-retention-select-label"
                id="video-retention-select"
                value={data.data_retention_days_video}
                // onChange={handleRetentionChange}
                inputProps={{ readOnly: true }}
              >
                {video_options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>
        }
      </Grid>
    </Grid >
  )
}

export default OrgDataRetention