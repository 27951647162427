import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { logErr, logInfo } from 'log'
import { getUser, saveAffiliateCid } from 'service/usersApi'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { getAffiliateUrl } from 'service/systemApi'

// Helmet puts the script into the <Head> tag of html
// must put this on home screen after sign in, the url should have tracking params
// ie. https://app.finmate.ai/home?linkId=lp_469523&sourceId=some_affiliate_id&tenantId=finmate-ai
export function TrackDeskClickTracker() {
  return (
    <Helmet>
      <script async src="//cdn.trackdesk.com/tracking.js"></script>
      <script>
        {`(function(t,d,k){(t[k]=t[k]||[]).push(d);t[d]=t[d]||t[k].f||function(){(t[d].q=t[d].q||[]).push(arguments)}})(window,"trackdesk","TrackdeskObject");
          trackdesk('finmate-ai', 'click');`}
      </script>
    </Helmet>
  )
}

// https://help.trackdesk.com/en/articles/6867895-stripe-integration-via-payment-links
// place on a page after "TrackDeskClickTracker", to pull CID and save to db
export function TrackDeskCIDToPay() {
  const navigate = useNavigate()
  const currentParams = new URLSearchParams(location.search)
  const affiliateId = currentParams.get('sourceId')

  const { data: user } = useQuery({
    queryKey: ['getUser'],
    queryFn: getUser
  })

  useEffect(() => {
    // In case user did not go through affiliate link
    // we can set the user with the affiliate_finmate_id in db.
    // this code triggers the trackdesk to create CID with home url and params
    async function run() {
      if (!affiliateId && user?.affiliate_finmate_id && !user.affiliate_cid) {
        const offerUrl = await getAffiliateUrl(user?.affiliate_finmate_id)
        if (offerUrl) {
          const queryString = offerUrl.search.substring(1)
          navigate(`${Path.Home}?${queryString}`)
        }
      }
    }
    run()
  }, [user?.affiliate_finmate_id])

  useEffect(() => {
    const script = () => {
      const cookie = document.cookie.match('(^|;)\\s*trakdesk_cid\\s*=\\s*([^;]+)')
      if (Array.isArray(cookie)) {
        try {
          // @ts-expect-error vanilla js
          const trakdeskCid = JSON.parse(cookie.pop())
          const cid = trakdeskCid['cid']

          // make sure the url has affiliate parameters to see the cid
          logInfo('TrackDeskCIDToPay CID', { cid, affiliateId })
          if (cid && affiliateId && affiliateId.length > 0)
            saveAffiliateCid(cid, affiliateId)
        } catch (e) {
          logErr('TrackDeskCIDToPay', { e })
        }
      }
    }
    if (user && !user.affiliate_cid)
      script()
  }, [user, affiliateId])

  return null
}
